import * as logClient from "@classdojo/log-client";
import isRetinaEnvironment from "is-retina";
import { useEffect, useState } from "react";

export type SetName = "beyond_school" | "class" | "positive_behavior" | "negative_behavior";

export type IconManifest = {
  icons: Array<{ id: string; hidden?: boolean }>;
  sizes: [number, number, number];
  svg?: boolean;
};

type IconManifestMap = Record<string, (() => Promise<IconManifest>) | IconManifest>;
type IconMap = Record<string, (() => Promise<string>) | string>;

export type IconEntry = { iconUrl: string; id: string; hidden?: boolean; label?: string };
export type IconSet = Record<string, IconEntry>;

export const TRANSPARENT_PNG_DATA_URL =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=";

let manifests: IconManifestMap;
let icons: IconMap;
let prefix: string = "uninitialized";
let bundler: string = "unknown";

const load = (() => {
  let loaded = false as boolean | Promise<void>;

  return async (): Promise<void> => {
    if (loaded) {
      return;
    }

    if (Config.bundler === "vite") {
      // We are jumping through more hoops here than in similar (Image.tsx) code,
      // beacuse an "eager glob" in dev mode explodes into ~1000 separate requests that have
      // to come back. This is to populate the hash mapping icons to their respective paths.
      // The way this executes in production is much more efficient, so we use globEager in
      // production vite, glob non-eager in development vite, and bridge it with optional
      // lazy imports, because we probably don't need to wait to paint the first load
      // until all of the icon sets have finished loading:
      const module = await (Config.nodeEnv === "development"
        ? import("./iconSetViteContentsDev")
        : import("./iconSetViteContents"));

      bundler = "vite";
      prefix = module.prefix;
      manifests = module.manifests;
      icons = module.icons;
      loaded = true;
    } else {
      bundler = "webpack";
      prefix = ".";

      const manifestContext = require.context("@web-monorepo/dojo-icons/src", true, /.+\.json$/);
      manifests = manifestContext.keys().reduce<IconManifestMap>((acc, curr) => {
        acc[curr] = manifestContext(curr);
        return acc;
      }, {});

      const iconsContext = require.context("@web-monorepo/dojo-icons/src", true, /.+\.(png|gif|svg|jpe?g)$/);
      icons = iconsContext.keys().reduce<IconMap>((acc, curr) => {
        acc[curr] = iconsContext(curr);
        return acc;
      }, {});

      loaded = true;
    }
  };
})();

const unwrapPromiseEntry = async <T>(icons: Record<string, (() => Promise<T>) | T>, key: string): Promise<T> => {
  const entry = icons[key];
  if (entry instanceof Function) {
    const unwrapped = await entry();
    icons[key] = unwrapped;
    return unwrapped;
  }

  return entry;
};

const loadIconManifest = async (name: SetName): Promise<IconManifest> => {
  await load();

  const manifestName = `${prefix}/${name}/manifest.json`;
  if (!manifests[manifestName]) {
    throw new Error(`couldn't find manifest for ${name}`);
  }

  return unwrapPromiseEntry(manifests, manifestName);
};

const useIconSetManifest = (name: SetName | null): null | IconManifest => {
  const [manifest, setManifest] = useState<IconManifest | null>(null);
  useEffect(() => {
    if (!name) {
      // bulk ignoring existing errors
      // eslint-disable-next-line @web-monorepo/no-setState-in-useEffect
      setManifest(null);
      return;
    }

    loadIconManifest(name).then(setManifest);
  }, [name]);

  return manifest;
};

export const loadIconSet = async (name: SetName): Promise<IconSet> => {
  const manifest = await loadIconManifest(name);

  const smallestSize = manifest.sizes[0];
  const shouldUseRetinaVersion = isRetinaEnvironment();
  const size = shouldUseRetinaVersion ? smallestSize * 2 : smallestSize;

  const iconSetBuilder: IconSet = {};
  await Promise.all(
    manifest.icons.map(async (entry) => {
      const iconId = entry.id;
      const path = `${prefix}/${name}/${size}/${iconId}.png`;
      if (!(path in icons)) {
        logClient.logException(new Error(`Couldn't find icon`), { set: name, iconId, bundler });
        return;
      }

      iconSetBuilder[iconId] = {
        ...entry,
        iconUrl: await unwrapPromiseEntry(icons, path),
      };
    }),
  );

  return iconSetBuilder;
};

export const useIconSet = (set: SetName | null): IconSet | null => {
  const manifest = useIconSetManifest(set);
  const [iconSet, setIconSet] = useState<IconSet | null>(null);

  useEffect(() => {
    if (set === null) {
      // bulk ignoring existing errors
      // eslint-disable-next-line @web-monorepo/no-setState-in-useEffect
      setIconSet(null);
    }

    if (!manifest || !set) return;

    loadIconSet(set).then(setIconSet);
  }, [set, manifest]);

  return iconSet;
};

export const useIconSrc = (set: SetName | null, iconId: string | null): string | null => {
  const manifest = useIconSetManifest(set);
  const [iconSrc, setIconSrc] = useState<string | null>(null);

  useEffect(() => {
    if (!manifest) return;

    const smallestSize = manifest.sizes[0];
    const shouldUseRetinaVersion = isRetinaEnvironment();
    const size = shouldUseRetinaVersion ? smallestSize * 2 : smallestSize;
    const path = `${prefix}/${set}/${size}/${iconId}.png`;

    if (!(path in icons)) {
      logClient.logException(new Error(`Couldn't find icon`), { set, iconId, bundler });
      return;
    }

    unwrapPromiseEntry(icons, path).then(setIconSrc);
  }, [manifest, set, iconId]);

  return iconSrc;
};

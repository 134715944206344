import * as Switch from "@radix-ui/react-switch";
import { forwardRef } from "react";
import classes from "./DDSToggle.module.css";

export type DDSToggleProps = React.ComponentPropsWithoutRef<typeof Switch.Root> & {
  /**
   * The size of the toggle
   * @default "large"
   */
  size?: "small" | "medium" | "large";
  /**
   * Whether the toggle is disabled
   */
  disabled?: boolean;
  /**
   * Optional id for the toggle
   */
  id?: string;
  /**
   * The controlled state of the toggle.
   * @default false
   */
  checked?: boolean;

  /**
   * Callback fired when the toggle state changes.
   * @param checked The new checked state
   */
  onCheckedChange?: (checked: boolean) => void;
} & (
    | {
        /**
         * The accessible label for the toggle
         */
        "aria-label": string;
        "aria-labelledby"?: never;
      }
    | {
        "aria-labelledby": string;
        "aria-label"?: never;
      }
  );

export const DDSToggle = forwardRef<React.ElementRef<typeof Switch.Root>, DDSToggleProps>(
  ({ "aria-label": label, size = "large", className, disabled, id, ...props }, ref) => {
    const toggleId = id || `dds-toggle-${Math.random().toString(36).slice(2, 11)}`;

    return (
      <Switch.Root
        className={[classes.root, classes[size], disabled && classes.disabled, className].filter(Boolean).join(" ")}
        disabled={disabled}
        id={toggleId}
        aria-label={label}
        {...props}
        ref={ref}
        data-name="dds.toggle"
      >
        <Switch.Thumb className={classes.thumb} />
      </Switch.Root>
    );
  },
);

DDSToggle.displayName = "DDSToggle";

import { autoTranslate } from "@web-monorepo/vite-auto-translate-plugin/runtime";
import { useState } from "react";
import { ThemeUIStyleObject } from "../../../nessie/stylingLib";
import { handleActionKeyDown } from "../../../utils/keyboard";
import WithClickOutside from "../WithClickOutside";
import Icon from "./Icon";
import styles from "./Styles";

export type IconPickerProps = {
  value: number | string;
  onChange: (value: number | string) => void;
  options: { value: number | string; iconUrl: string; label?: string }[];
  tabIndex?: number;
};

/**
 * Icon picker
 */
export default function IconPicker({ value, onChange, options, tabIndex = 0 }: IconPickerProps) {
  const [isOpen, setIsOpen] = useState(false);

  const closePicker = () => setIsOpen(false);
  const togglePicker = () => setIsOpen(!isOpen);

  const onKeyDown = handleActionKeyDown({ onAction: togglePicker, onCancel: closePicker });

  return (
    <div sx={{ position: "relative" as const, width: 90 }}>
      <WithClickOutside onClickOutside={closePicker}>
        <div
          data-name="iconPickerToggle"
          sx={toggleContainer}
          onClick={togglePicker}
          tabIndex={tabIndex}
          onKeyDown={onKeyDown}
          // eslint-disable-next-line @web-monorepo/no-jsx-role-button
          role="button"
          aria-expanded={isOpen}
          aria-label={autoTranslate("Select an icon")}
        >
          <img
            alt=""
            data-name="iconPickerCurrentIcon"
            sx={styles.toggleImage}
            src={options.find((opt) => String(opt.value) === String(value))?.iconUrl}
          />

          <span sx={styles.caret} />
        </div>

        {isOpen && (
          <div
            data-name="iconPickerContent"
            sx={{
              display: "grid",
              gridTemplateColumns: `repeat(5, 1fr)`,
              gap: "dt_xxs",
              ...pickerContainer,
            }}
          >
            {options.map((icon, i) => {
              // console.info("icon", options);
              return (
                <Icon
                  key={i}
                  icon={icon}
                  onSelect={() => {
                    onChange(icon.value);
                    togglePicker();
                  }}
                  aria-label={autoTranslate("__value__ icon", { value: icon.label ?? String(icon.value) })}
                />
              );
            })}
          </div>
        )}
      </WithClickOutside>
    </div>
  );
}

const toggleContainer: ThemeUIStyleObject = {
  backgroundColor: "dt_background_secondary",
  border: "dt_card",
  borderRadius: "dt_radius_s",
  textAlign: "center",
  padding: "s",
  width: 90,
  height: 90,
  cursor: "pointer",
};

const pickerContainer: ThemeUIStyleObject = {
  backgroundColor: "dt_background_primary",
  border: `dt_divider`,
  marginTop: "dt_xs",
  borderRadius: "dt_radius_s",
  position: "absolute",
  left: "50%",
  padding: "dt_m",
  transform: "translate(-50%, 0)",
  boxShadow: "dt_shadow_shadezies_small",
  zIndex: 120,
  overflowY: "auto",
  overflowX: "hidden",
};

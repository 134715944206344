import * as React from "react";
import { ReactModal } from "@web-monorepo/dds";
import { ClassNames, CSSInterpolation } from "../../nessie/stylingLib";
import { theme } from "./theme";
import { NessieSpaceSizes } from "./designTokens";
import * as logClient from "@classdojo/log-client";
import { ModalsContext } from "../../pods/automatedEvents/modalsContext";

export const FullscreenModal = ({
  children,
  isOpen,
  onRequestClose,
  overlayStyleOverrides = {},
  "data-name": dataName,
  "event-metadata": metadata,
  experiments: experiments,
}: React.PropsWithChildren<{
  isOpen: boolean;
  onRequestClose?: () => void;
  overlayStyleOverrides?: CSSInterpolation;
  /**
   * The name will get used for automated product events.
   * @see https://www.notion.so/classdojo/Automatic-Product-Events-for-Web-bfc580f10a914c3ba514e5ec20f8ef9e?pvs=4
   */
  "data-name"?: string;
  "event-metadata"?: object;
  experiments?: string[];
}>) => {
  const { addModal, removeModal } = React.useContext(ModalsContext);
  function logOpenEvent() {
    if (!dataName) {
      logClient.logMessage("WARN", `${logClient.getSite()}.modal.missingDataName`);
      return;
    }

    if (addModal) addModal(dataName);
    logClient.logEvent({
      eventName: `${logClient.getSite()}.modal.${dataName}.open`,
      automatedEvent: true,
      metadata,
      experiments,
    });
  }

  function logCloseEvent() {
    if (!dataName) return;

    if (removeModal) removeModal(dataName);
    logClient.logEvent({
      eventName: `${logClient.getSite()}.modal.${dataName}.close`,
      automatedEvent: true,
      metadata,
      experiments,
    });
  }

  return (
    <ClassNames>
      {({ css, cx }) => {
        // This overrides styles for the div react-modal uses for the overlay
        const overlayClassName = css({
          backgroundColor: "rgba(66, 62, 93, .9)",
          // outline: "none",
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
          position: "fixed",
          zIndex: theme.zIndexes.modalOverlay,
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        });

        // This overrides styles for the div react-modal uses to render the content.
        const contentClassName = css({
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
          position: "relative",
          width: "100%",
          // outline: "none",
          overflowY: "auto",
        });

        return (
          <ReactModal
            data={{
              name: dataName || "fullscreen_modal:css:react_modal",
              "event-metadata": JSON.stringify(metadata),
              experiments,
            }}
            ariaHideApp={false}
            onAfterOpen={logOpenEvent}
            onAfterClose={logCloseEvent}
            onRequestClose={onRequestClose}
            overlayClassName={cx(overlayClassName, css(overlayStyleOverrides))}
            className={contentClassName}
            isOpen={isOpen}
          >
            {children}
          </ReactModal>
        );
      }}
    </ClassNames>
  );
};

// ---------------------------------------------
// Fullscreen Modal

export const FullscreenModalContent = ({
  children,
  padding = "xl",
}: React.PropsWithChildren<{
  padding?: NessieSpaceSizes;
}>) => (
  <div
    sx={{
      padding,
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
      flexShrink: 0,
      backgroundColor: "dt_background_primary",
      position: "relative",
    }}
  >
    {children}
  </div>
);

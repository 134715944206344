import * as React from "react";
import * as TabsPrimitive from "@radix-ui/react-tabs";
import classes from "./DDSTabs.module.css";

/** DDSTabs - based off of [ShadCN](https://ui.shadcn.com/docs/components/tabs). See [storybook](https://components.classdojo.com/?path=/story/components-ddstabs--default) for usage. */
const DDSTabs = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Root>
>((props, ref) => {
  const { children, className, ...rest } = props;
  return (
    <TabsPrimitive.Root ref={ref} className={[classes.container, className].filter(Boolean).join(" ")} {...rest}>
      {children}
    </TabsPrimitive.Root>
  );
});

const DDSTabsList = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.List>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.List>
>((props, ref) => <TabsPrimitive.List ref={ref} className={classes.tabsList} {...props} />);
DDSTabsList.displayName = TabsPrimitive.List.displayName;

const DDSTabTrigger = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Trigger> & {
    /** The value of the tab trigger */
    value: string;
    /** When true, prevents the user from interacting with the tab */
    disabled?: boolean;
    /**
     * The name will get used for automated product events.
     * @see https://www.notion.so/classdojo/Automatic-Product-Events-for-Web-bfc580f10a914c3ba514e5ec20f8ef9e?pvs=4
     */
    ["data-name"]: string;
  }
>((props, ref) => <TabsPrimitive.Trigger ref={ref} className={classes.tabsTrigger} {...props} />);
DDSTabTrigger.displayName = TabsPrimitive.Trigger.displayName;

const DDSTabContent = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Content> & {
    /** The value of the tab content */
    value: string;
  }
>((props, ref) => <TabsPrimitive.Content ref={ref} className={classes.tabsContent} {...props} />);
DDSTabContent.displayName = TabsPrimitive.Content.displayName;

export { DDSTabs, DDSTabsList, DDSTabTrigger, DDSTabContent };

import * as React from "react";
import { CloseIcon } from "../../nessie/icons";
import { RAW_cssValue, ThemeUIStyleObject } from "../../nessie/stylingLib";
import { UnstyledButton } from "../buttons";
import { HEADER_HEIGHT, HEADER_LATERAL_PADDING } from "./Styles";

/**
 * Simple component for rendering the close button in our modals, internally used
 * by our modals.
 */
type ModalCloseButtonProps = {
  close: React.MouseEventHandler;
  className?: string;
  /**
   * The name will get used for automated product events.
   * @see https://www.notion.so/classdojo/Automatic-Product-Events-for-Web-bfc580f10a914c3ba514e5ec20f8ef9e?pvs=4
   */
  "data-name"?: string;
  "aria-label"?: string;
  size?: "xs" | "s" | "m" | "l" | "xl" | "xxl";
  sx?: ThemeUIStyleObject;
};

const ModalCloseButton = ({
  close,
  className,
  size = "m",
  "data-name": dataName = "closeModalButton",
  "aria-label": ariaLabel = "Close modal",
  sx,
}: ModalCloseButtonProps): JSX.Element => {
  return (
    <UnstyledButton
      data-name={dataName}
      className={className}
      sx={{ ...modalHeaderClose, ...sx }}
      onClick={close}
      aria-label={ariaLabel}
    >
      <CloseIcon size={size} />
    </UnstyledButton>
  );
};

export default ModalCloseButton;

const modalHeaderClose: ThemeUIStyleObject = {
  color: "dt_content_primary",
  position: "absolute",
  right: "0",
  top: "0",
  paddingX: RAW_cssValue(HEADER_LATERAL_PADDING),
  height: HEADER_HEIGHT,
  display: "flex",
  alignItems: "center",
  ":hover svg": {
    color: "dt_content_tertiary",
  },

  "@media (max-width: 400px)": {
    paddingX: "1rem",
    height: "4.8rem",
  },
};

import type { FunctionComponent, PropsWithChildren } from "react";
import * as Classes from "./DDSHeadlineText.module.css";

export type DDSHeadlineTextProps = {
  id?: string;
  as?: "span" | "h1" | "h2" | "h3" | "h4" | "h5";
  level?: 1 | 2 | 3;
  className?: string;
};

export const DDSHeadlineText: FunctionComponent<PropsWithChildren<DDSHeadlineTextProps>> = ({
  id,
  as: tagName = "h2",
  level = 1,
  className,
  children,
}) => {
  const Tag = tagName;
  return (
    <Tag id={id} className={`${className ?? ""} ${Classes[`headline${level}`]}`}>
      {children}
    </Tag>
  );
};

import * as React from "react";
import { Space } from "../../nessie";
import { HeartIcon, CommentIcon, CheckIcon } from "../../nessie/icons";
import { ThemeUIStyleObject } from "../../nessie/stylingLib";
import { TranslatedString } from "../../pods/i18n/translate";
import { UnstyledButton } from "../buttons";
import { autoTranslate } from "@web-monorepo/vite-auto-translate-plugin/runtime";

export type PostReceiptsProps = {
  readCount?: number;
  likeCount?: number;
  commentCount?: number;
  toggleReads?: () => void;
  toggleLikes?: () => void;
  toggleComments?: () => void;
};

// eslint-disable-next-line complexity
const PostReceipts = ({
  readCount,
  likeCount,
  commentCount,
  toggleReads,
  toggleLikes,
  toggleComments,
}: PostReceiptsProps): JSX.Element => {
  let likesIndicator, readsIndicator, commentsIndicator;
  if (likeCount != null && likeCount > 0 && toggleLikes) {
    const text =
      likeCount === 1
        ? autoTranslate("1 like", { count: likeCount })
        : autoTranslate("__count__ likes", { count: likeCount.toFixed() });
    likesIndicator = renderReceiptIndicator(
      text,
      <HeartIcon size="xs" color="dt_content_tertiary" />,
      toggleLikes,
      "likesIndicator",
    );
  }
  if (commentCount != null && commentCount > 0 && toggleComments) {
    const text =
      commentCount === 1
        ? autoTranslate("1 comment", { count: commentCount })
        : autoTranslate("__count__ comments", { count: commentCount.toFixed() });
    commentsIndicator = renderReceiptIndicator(
      text,
      <CommentIcon size="xs" color="dt_content_tertiary" />,
      toggleComments,
      "commentsIndicator",
    );
  }
  if (readCount != null && readCount > 0 && toggleReads) {
    const text =
      readCount === 1
        ? autoTranslate("1 view", { count: readCount })
        : autoTranslate("__count__ views", { count: readCount.toFixed() });
    readsIndicator = renderReceiptIndicator(
      text,
      <CheckIcon size="xs" color="dt_content_tertiary" />,
      toggleReads,
      "readsIndicator",
    );
  }

  if (likesIndicator || readsIndicator || commentsIndicator) {
    return (
      <ReceiptsBar>
        <div sx={{ display: "flex" }} aria-live="polite" aria-relevant="all">
          {likesIndicator}
          {likesIndicator && <Space kind="inline" size="dt_l" />}
          {commentsIndicator}
          {commentsIndicator && <Space kind="inline" size="dt_l" />}
          {readsIndicator}
        </div>
      </ReceiptsBar>
    );
  }
  return <span />;
};

// eslint-disable-next-line react-refresh/only-export-components
export default React.memo(PostReceipts);

const renderReceiptIndicator = (text: TranslatedString, icon: JSX.Element, action: () => void, testName: string) => {
  const handleClick = (evt: React.MouseEvent) => {
    evt.preventDefault();
    action();
  };
  return (
    <UnstyledButton onClick={handleClick} data-name={testName} sx={{ display: "flex", position: "relative" }}>
      {icon}
      <span role="status" sx={styles.receiptIndicator} aria-label={text}>
        {text}
      </span>
    </UnstyledButton>
  );
};

export const ReceiptsBar = ({ children }: { children: React.ReactNode }): JSX.Element => {
  return <div sx={styles.receipts}>{children}</div>;
};

const styles: Record<string, ThemeUIStyleObject> = {
  receipts: {
    margin: "dt_m",
  },
  receiptIndicator: {
    fontSize: "1.2rem",
    fontWeight: "bold",
    color: "dt_content_tertiary",
    cursor: "pointer",
    marginLeft: "dt_xs",
  },
};

"use client";

import * as React from "react";
import * as DropdownMenuPrimitive from "@radix-ui/react-dropdown-menu";
import styles from "./DDSDropdownMenu.module.css";

const DDSDropdownMenu = ({
  modal = false,
  ...props
}: React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Root>) => (
  <DropdownMenuPrimitive.Root modal={modal} {...props} />
);

const DDSDropdownMenuTrigger = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Trigger> & {
    asChild?: boolean;
  }
>(({ asChild = false, className, ...props }, ref) => (
  <DropdownMenuPrimitive.Trigger
    ref={ref}
    className={`${styles.trigger} ${className ?? ""}`}
    asChild={asChild}
    {...props}
  />
));
DDSDropdownMenuTrigger.displayName = "DDSDropdownMenuTrigger";

const DDSDropdownMenuGroup = DropdownMenuPrimitive.Group;

const DDSDropdownMenuPortal = DropdownMenuPrimitive.Portal;

const DDSDropdownMenuContent = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Content>
>(({ className, sideOffset = 4, ...props }, ref) => (
  <DDSDropdownMenuPortal>
    <DropdownMenuPrimitive.Content
      ref={ref}
      sideOffset={sideOffset}
      className={`${styles.content} ${className ?? ""}`}
      {...props}
    />
  </DDSDropdownMenuPortal>
));
DDSDropdownMenuContent.displayName = "DDSDropdownMenuContent";

const DDSDropdownMenuItem = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Item> & {
    inset?: boolean;
    variant?: "default" | "destructive";
    "data-name": string;
  }
>(({ className, inset, variant = "default", ...props }, ref) => (
  <DropdownMenuPrimitive.Item
    ref={ref}
    className={`${styles.item} ${inset ? styles.inset : ""} ${className ?? ""} ${variant === "destructive" ? styles.destructive : ""}`}
    {...props}
  />
));
DDSDropdownMenuItem.displayName = "DDSDropdownMenuItem";

const DDSDropdownMenuLabel = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.Label>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Label> & {
    inset?: boolean;
  }
>(({ className, inset, ...props }, ref) => (
  <DropdownMenuPrimitive.Label
    ref={ref}
    className={`${styles.label} ${inset ? styles.inset : ""} ${className ?? ""}`}
    {...props}
  />
));
DDSDropdownMenuLabel.displayName = "DDSDropdownMenuLabel";

const DDSDropdownMenuSeparator = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.Separator>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Separator>
>(({ className, ...props }, ref) => (
  <DropdownMenuPrimitive.Separator ref={ref} className={`${styles.separator} ${className ?? ""}`} {...props} />
));
DDSDropdownMenuSeparator.displayName = "DDSDropdownMenuSeparator";

// only added the items we need, add the rest as needed
// the items that are commented out were originally added by ai
export {
  DDSDropdownMenu,
  DDSDropdownMenuTrigger,
  DDSDropdownMenuContent,
  DDSDropdownMenuItem,
  //   DDSDropdownMenuCheckboxItem,
  //   DDSDropdownMenuRadioItem,
  DDSDropdownMenuLabel,
  DDSDropdownMenuSeparator,
  //   DDSDropdownMenuShortcut,
  DDSDropdownMenuGroup,
  DDSDropdownMenuPortal,
  //   DDSDropdownMenuSub,
  //   DDSDropdownMenuSubContent,
  //   DDSDropdownMenuSubTrigger,
  //   DDSDropdownMenuRadioGroup,
};

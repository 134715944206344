import filesize from "filesize";
import { FileIconNames, FileTypeIconNames } from "../components/misc/FileTypeIcon";
import { autoTranslate } from "@web-monorepo/vite-auto-translate-plugin/runtime";

const DEFAULT_ICON = "file_other";

const EXTENSION_STRINGS: Record<string, string> = {
  doc: "dojo.common:class_story.file_attachment.doc",
  pdf: "dojo.common:class_story.file_attachment.pdf",
  xls: "dojo.common:class_story.file_attachment.xls",
  ppt: "dojo.common:class_story.file_attachment.ppt",
  txt: "dojo.common:class_story.file_attachment.txt",
  rtf: "dojo.common:class_story.file_attachment.rtf",
  csv: "dojo.common:class_story.file_attachment.csv",
  mp3: "dojo.common:class_story.file_attachment.mp3",
};

const EXTENSION_REPLACEMENTS: Record<string, string> = {
  docx: "doc",
  xlsx: "xls",
  pptx: "ppt",
};
const EXTENSIONS_WITH_ICON = Object.keys(EXTENSION_STRINGS);

function getFileExtension(filename = "") {
  if (!filename.includes(".")) return null;
  const extension = filename.slice(filename.lastIndexOf(".") + 1).toLowerCase();
  return EXTENSION_REPLACEMENTS[extension] || extension;
}

function isIconName(icon: string): icon is FileTypeIconNames {
  return FileIconNames.includes(icon);
}

function getTranslatedExtension(extension: string) {
  const EXTENSION_STRINGS: Record<string, string> = {
    doc: autoTranslate("Word Document"),
    pdf: autoTranslate("PDF Document"),
    xls: autoTranslate("Excel Document"),
    ppt: autoTranslate("PowerPoint Document"),
    txt: autoTranslate("Text Document"),
    rtf: autoTranslate("Text Document"),
    csv: autoTranslate("CSV Document"),
    mp3: autoTranslate("mp3 File"),
  };

  return EXTENSION_STRINGS[extension];
}

export function getFileIconName(filename?: string): FileTypeIconNames {
  if (!filename) {
    return DEFAULT_ICON;
  }
  let extension = getFileExtension(filename);

  if (extension) {
    if (extension === "csv") {
      // not in EXTENSION_REPLACEMENTS because we do want to render
      // filetype differently
      extension = "xls";
    }
    if (EXTENSIONS_WITH_ICON.includes(extension)) {
      const icon = `file_${extension}`;
      if (isIconName(icon)) {
        return icon;
      }
    }
    return DEFAULT_ICON;
  }
  return DEFAULT_ICON;
}

export function getPrettyFileSize(bytes?: number): string | null {
  return bytes ? filesize(bytes, { round: 0 }).replace(" ", "") : null;
}

export function getFileDescription(filename?: string): string | null {
  const extension = getFileExtension(filename);
  return extension ? getTranslatedExtension(extension) : null;
}

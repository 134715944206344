import uniqueId from "lodash/uniqueId";
import { useState } from "react";
import { LabelFormElement } from "../..";
import { ThemeUIStyleObject, keyframes } from "../../nessie/stylingLib";
import BodyText from "./typography/bodyText";

export type TextFieldProps = Omit<React.InputHTMLAttributes<HTMLInputElement>, "onChange"> &
  TextFieldConditionalProps &
  TextFieldConstantProps;

type TextFieldConditionalProps =
  | {
      label?: never;
      ["aria-label"]: string;
    }
  | {
      label: string | React.ReactElement;
      ["aria-label"]?: string;
    };

type TextFieldConstantProps = {
  valid?: boolean;
  validationMessage?: string | null;
  message?: string;
  onChange?: (value: string) => void;
  /**
   * If, when developing, you try to use password in a textField, know that we have a specific component for that PasswordTextField
   * with features exclusive to passwords
   */
  type?: "text" | "number" | "email" | "search" | "tel" | "url";
  /**
   * The name will get used for automated product events.
   * @see https://www.notion.so/classdojo/Automatic-Product-Events-for-Web-bfc580f10a914c3ba514e5ec20f8ef9e?pvs=4
   */
  "data-name"?: string;
  leftIndent?: ThemeUIStyleObject["paddingLeft"];
  rightIndent?: ThemeUIStyleObject["paddingRight"];
  innerRef?: React.Ref<HTMLInputElement>;
  forceTouch?: boolean;
  maxHeight?: ThemeUIStyleObject["height"];
};

// eslint-disable-next-line complexity
export function TextField({
  valid = true,
  label,
  maxLength,
  validationMessage,
  message,
  onChange,
  className,
  id,
  children,
  leftIndent,
  rightIndent,
  innerRef,
  forceTouch,
  maxHeight,
  ...props
}: TextFieldProps) {
  const [inputId] = useState(uniqueId("textFieldInputId"));
  const [touched, setTouched] = useState(forceTouch ?? false);
  const descriptionId = `${inputId}-description`;
  const alertId = `${inputId}-alert`;
  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const value = event.target.value;
    onChange?.(value);
  }

  return (
    <div
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        gap: "xs",
        ":focus-within": {
          [`#${descriptionId}`]: valid || (!props.value && !touched) ? visibleStyles : {},
        },
      }}
      className={className}
      onBlur={() => {
        if (!touched) setTouched(true);
      }}
    >
      {label && <LabelFormElement htmlFor={id || inputId} labelText={label} />}
      <div sx={{ position: "relative", display: "flex", alignItems: "center", width: "100%" }}>
        <input
          maxLength={maxLength || undefined}
          onChange={handleChange}
          sx={{
            ...textFieldStyles,
            ...(!valid && touched ? invalidTextFieldStyle : {}),
            ...(leftIndent ? { textIndent: leftIndent } : {}),
            ...(rightIndent ? { paddingRight: rightIndent } : {}),
            ...(maxHeight ? { maxHeight } : {}),
          }}
          id={id || inputId}
          aria-describedby={descriptionId}
          ref={innerRef}
          {...props}
        />
        {children}
      </div>
      {validationMessage && !valid && touched ? (
        <BodyText
          level={2}
          data-name="textFieldValidationMessage"
          sx={{ paddingLeft: "xs", animation: `${fadeIn} 0.2s ease-in` }}
          role="alert"
          kind="danger"
          id={alertId}
        >
          {validationMessage}
        </BodyText>
      ) : null}
      {message && (
        <BodyText
          level={2}
          data-name="textFieldMessage"
          sx={{ paddingLeft: "xs", transition: "opacity 0.2s ease-in", ...invisibleStyles }}
          kind="tertiary"
          id={descriptionId}
        >
          {message}
        </BodyText>
      )}
    </div>
  );
}
// if height needs to be changed, check how it affects the textArea on messaging
// eslint-disable-next-line react-refresh/only-export-components
export const TEXT_FIELD_HEIGHT = 62;

// Text field styles
const textFieldStyles: ThemeUIStyleObject = {
  boxSizing: "border-box",
  outline: "none",
  width: "100%",
  height: TEXT_FIELD_HEIGHT,
  paddingY: "dt_m",
  paddingX: "dt_l",
  fontSize: 18,
  lineHeight: "22px",
  letterSpacing: "-.25px",
  fontWeight: "dt_semiBold",
  color: "dt_content_primary",
  borderRadius: "dt_radius_s",
  border: "dt_functional",
  caretColor: "dt_content_accent",
  ":active, :focus": {
    border: "dt_active",
    color: "dt_content_primary",
  },
  ":disabled, :read-only": {
    backgroundColor: "dt_background_secondary",
    border: "dt_disabled",
    color: "dt_content_secondary",
    "::placeholder": {
      color: "dt_content_secondary",
    },
  },
  "::placeholder": {
    color: "dt_content_secondary",
  },
  "@media (max-width: 320px)": {
    "::placeholder": {
      fontSize: "12px",
    },
  },
};

// eslint-disable-next-line react-refresh/only-export-components
export const invalidTextFieldStyle: ThemeUIStyleObject = {
  borderColor: "dt_content_danger",
  ":active, :focus": {
    borderColor: "dt_content_danger",
  },
};

/**
 * it is important that this text is always rendered to help with context for screen readers
 */
const invisibleStyles: ThemeUIStyleObject = {
  position: "absolute",
  left: "-10000px",
  top: "auto",
  width: "1px",
  height: "1px",
  overflow: "hidden",
  opacity: 0,
};
const visibleStyles: ThemeUIStyleObject = {
  position: "relative",
  left: "0",
  top: "0",
  width: "fit-content",
  height: "fit-content",
  overflow: "hidden",
  opacity: 1,
};

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

import { useState, forwardRef } from "react";
import FilePicker, { uploadTo } from "@web-monorepo/file-upload";
import { toast } from "react-toastify";
import { DDSButton, DDSToast } from "@web-monorepo/dds";
import { autoTranslate } from "@web-monorepo/vite-auto-translate-plugin/runtime";
import { LabelText, BodyText } from "../../nessie";
import { Attachment } from "./PostAttachments";
import { captureException } from "@web-monorepo/telemetry";

type UploadState =
  | "idle" // Initial state, no file selected
  | "selecting" // User is interacting with FilePicker
  | "selected" // File has been chosen but not yet uploaded
  | "uploading" // File is being uploaded to S3
  | "uploaded"; // Upload completed successfully

interface CaptionUploaderProps {
  attachment: Attachment;
  onCaptionUploaded: (attachmentId: string, captionUrl: string) => void;
  onClose: () => void;
}

export const CaptionUploader = forwardRef<HTMLDivElement, CaptionUploaderProps>(
  ({ attachment, onCaptionUploaded, onClose }, ref) => {
    const [caption, setCaption] = useState<File[]>([]);
    const [uploadState, setUploadState] = useState<UploadState>("idle");

    const handleUpload = async () => {
      if (!attachment._id) return;

      try {
        setUploadState("uploading");
        const attachments = await uploadTo.s3({ bucket: "caption", files: caption });
        const captionPath = attachments[0].fullPath;
        onCaptionUploaded(attachment._id, captionPath);
        setUploadState("uploaded");
      } catch (err) {
        if (!(err instanceof Error)) throw err;

        toast(
          <DDSToast
            variant="error"
            text={autoTranslate("There was a problem submitting your request, please try again.")}
          />,
        );
        setUploadState("selected");
        captureException(err);
      }
    };

    const getButtonText = (state: UploadState) => {
      switch (state) {
        case "uploading":
          return autoTranslate("Uploading...");
        case "uploaded":
          return autoTranslate("Captions Saved");
        default:
          return autoTranslate("Save Captions");
      }
    };

    return (
      <div ref={ref}>
        <FilePicker
          allowedFileConfig={{ extensions: [".vtt"], maxFiles: 1 }}
          onCancel={() => {
            setCaption([]);
            setUploadState("idle");
            onClose();
          }}
          onChange={(newFiles) => {
            setCaption(newFiles);
            setUploadState("selected");
          }}
          clearOnUnmount={true}
          onInvalidFileDrop={(t) => toast(<DDSToast variant="error" text={t} />)}
          prompt={
            <>
              <LabelText>Add captions (VTT files)</LabelText>
              <BodyText>or drag and drop</BodyText>
            </>
          }
        />
        <div sx={{ display: "flex", gap: "dt_s", alignItems: "center", justifyContent: "flex-end", marginTop: "dt_s" }}>
          <DDSButton
            size="small"
            data-name="add-captions"
            disabled={caption.length === 0 || uploadState === "uploading" || uploadState === "uploaded"}
            onClick={handleUpload}
            variant="secondary"
          >
            {getButtonText(uploadState)}
          </DDSButton>
        </div>
      </div>
    );
  },
);

import { autoTranslate } from "@web-monorepo/vite-auto-translate-plugin/runtime";
import * as React from "react";
import { TranslatedString } from "../../pods/i18n/translate";
import {
  DDSDialog,
  DDSDialogContent,
  DDSDialogHeader,
  DDSDialogBody,
  DDSDialogFooter,
  DDSButton,
} from "@web-monorepo/dds";

type ConfirmModalProps = {
  width?: string;
  title: TranslatedString;
  bodyText?: React.ReactNode;
  children?: React.ReactNode;
  confirmText?: string;
  cancelText?: string;
  danger?: boolean;
  /**
   * The name will get used for automated product events.
   * @see https://www.notion.so/classdojo/Automatic-Product-Events-for-Web-bfc580f10a914c3ba514e5ec20f8ef9e?pvs=4
   */
  "data-name"?: string;
  confirm: () => void;
  close: () => void;
  spaceAroundButtons?: boolean;
};

const ConfirmModal = ({
  width = "40rem",
  title,
  bodyText,
  children,
  confirmText,
  cancelText,
  danger,
  "data-name": dataName,
  confirm,
  close,
  spaceAroundButtons,
}: ConfirmModalProps): JSX.Element => {
  const confirmCTA = React.useMemo(() => confirmText || autoTranslate("OK"), [confirmText]);
  const cancelCTA = React.useMemo(() => cancelText || autoTranslate("Cancel"), [cancelText]);

  return (
    <DDSDialog
      open
      data-name={dataName}
      onOpenChange={(open) => {
        if (!open) {
          close();
        }
      }}
    >
      <DDSDialogContent sx={{ width }}>
        <DDSDialogHeader title={title} />
        <DDSDialogBody>{bodyText ?? children}</DDSDialogBody>
        <DDSDialogFooter>
          <div
            sx={{ display: "flex", justifyContent: spaceAroundButtons ? "space-around" : "space-between", gap: "dt_m" }}
          >
            <DDSButton data-name={`${dataName}-cancel`} onClick={close} variant="secondary">
              {cancelCTA}
            </DDSButton>
            <DDSButton
              data-name={`${dataName}-ok`}
              variant={danger ? "destructive" : undefined}
              onClick={() => {
                confirm();
                close();
              }}
            >
              {confirmCTA}
            </DDSButton>
          </div>
        </DDSDialogFooter>
      </DDSDialogContent>
    </DDSDialog>
  );
};

export default ConfirmModal;

import * as React from "react";
import { Button, Space } from "../../nessie";
import { TranslatedString } from "../../pods/i18n/translate";
import { autoTranslate } from "@web-monorepo/vite-auto-translate-plugin/runtime";

type CommentsDisabledTooltipProps = {
  context: "school" | "classOrStudentStory";
  viewerType: "teacher" | "parent" | "student";
  contactName?: string;
  enableComments: React.ComponentPropsWithoutRef<typeof Button>["onClick"];
  messagePoster?: () => void;
};

// eslint-disable-next-line react-refresh/only-export-components
const CommentsDisabledTooltip = ({
  context,
  viewerType,
  contactName,
  enableComments,
  messagePoster,
}: CommentsDisabledTooltipProps): JSX.Element => {
  let commentsDisabledHeader: TranslatedString | null = null;
  let commentsDisabledText: TranslatedString | null = null;
  let commentsDisabledCTAText: TranslatedString | null = null;
  let commentsDisabledAction;
  if (viewerType === "teacher") {
    if (context === "school") {
      if (enableComments) {
        commentsDisabledHeader = autoTranslate("Turn on comments?");
        commentsDisabledText = autoTranslate("Let parents leave replies on School Story posts.");
        commentsDisabledCTAText = autoTranslate("Ok");
        commentsDisabledAction = enableComments;
      } else {
        commentsDisabledText = autoTranslate("__schoolName__ has comments turned off on School Story posts.", {
          schoolName: contactName || "",
        });
      }
    } else {
      if (enableComments) {
        commentsDisabledHeader = autoTranslate("Turn on comments?");
        commentsDisabledText = autoTranslate("Let parents leave replies on Class Story posts.");
        commentsDisabledCTAText = autoTranslate("Ok");
        commentsDisabledAction = enableComments;
      } else {
        commentsDisabledHeader = autoTranslate("Comments are off");
        commentsDisabledText = contactName
          ? autoTranslate("__teacherName__ does not allow comments on their posts.", {
              teacherName: contactName || "",
            })
          : null;
      }
    }
  } else if (viewerType === "parent") {
    if (context === "school") {
      commentsDisabledText = autoTranslate("__schoolName__ has comments turned off on School Story posts.", {
        schoolName: contactName || "",
      });
    } else {
      // eslint-disable-next-line @web-monorepo/no-invalid-translation-prefixes
      commentsDisabledText = autoTranslate("Commenting is off. Send a message to respond!");
      commentsDisabledCTAText = autoTranslate("Message __teacherName__", {
        teacherName: contactName || "",
      });
      commentsDisabledAction = messagePoster;
    }
  }

  return (
    <div sx={{ display: "flex", flexDirection: "column" }}>
      <div
        sx={{
          color: "dt_content_accent",
          fontSize: "1.8rem",
          fontWeight: 600,
        }}
      >
        {commentsDisabledHeader}
      </div>
      <Space size="dt_s" />
      <div>{commentsDisabledText}</div>
      <Space size="dt_s" />
      {commentsDisabledCTAText && commentsDisabledAction && (
        <Button data-name="commentsDisabledCTA" onClick={commentsDisabledAction}>
          {commentsDisabledCTAText}
        </Button>
      )}
    </div>
  );
};

// eslint-disable-next-line react-refresh/only-export-components
export default React.memo(CommentsDisabledTooltip);

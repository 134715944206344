import * as React from "react";
import { useEffect, useState } from "react";
import { BodyText, CheckboxRadioGroup } from "../../nessie";
import { CheckboxRadioInputsType } from "../../nessie/components/CheckboxRadio/CheckboxRadioInput";
import { autoTranslate } from "@web-monorepo/vite-auto-translate-plugin/runtime";

type LegalSectionProps = {
  needsDataTransferConsent?: boolean;
  setPrivacyPolicyApproved: (value: boolean) => void;
  copy: {
    creatingAccountExplicit: React.ReactNode;
    dataTransfer: React.ReactNode;
    hide: React.ReactNode;
    expand: React.ReactNode;
    creatingAccountImplicit: React.ReactNode;
    howItWorks: React.ReactNode;
    howItWorksDetails: React.ReactNode;
    inControl: React.ReactNode;
    inControlDetails: React.ReactNode;
  };
  newSignUp?: boolean;
};

const LegalSection = ({
  needsDataTransferConsent,
  setPrivacyPolicyApproved,
  copy,
  newSignUp = true,
}: LegalSectionProps): JSX.Element => {
  const [hasApprovedPrivacyPolicy, setHasApprovedPrivacyPolicy] = useState(!newSignUp);

  useEffect(() => {
    setPrivacyPolicyApproved(hasApprovedPrivacyPolicy);
    // don't include `setDataTransferConsentGranted` in effect dependencies so it
    // doesn't execute if the passed function reference changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasApprovedPrivacyPolicy]);

  const legalInputs: CheckboxRadioInputsType[] = [];
  if (newSignUp) {
    legalInputs.push({
      labelText: copy.creatingAccountExplicit,
      checked: hasApprovedPrivacyPolicy,
      onChange: (e) => setHasApprovedPrivacyPolicy(e.target.checked),
    });
  }

  if (needsDataTransferConsent) {
    return (
      <div sx={{ display: "flex", flexDirection: "column", gap: "s" }}>
        <CheckboxRadioGroup
          groupName="legal"
          groupType="checkbox"
          aria-label={autoTranslate("Legal section")}
          inputs={legalInputs}
        />
      </div>
    );
  }

  return (
    <BodyText
      level={2}
      sx={{
        a: { color: "dt_content_accent" },
        textAlign: "center",
      }}
    >
      {copy.creatingAccountImplicit}
    </BodyText>
  );
};

export default LegalSection;

import React, { useCallback, useEffect, useLayoutEffect, useRef } from "react";
import * as Dialog from "@radix-ui/react-dialog";
import * as VisuallyHidden from "@radix-ui/react-visually-hidden";
import { autoTranslate } from "@web-monorepo/vite-auto-translate-plugin/runtime";
import { DDSButton } from "../DDSButton/DDSButton";
import classes from "./DDSDialog.module.css";
import { type ReactNode } from "react";
import { DDSIconButton } from "../DDSIconButton/DDSIconButton";
import { IconArrowLeft, IconX } from "@web-monorepo/dds-icons";
import { getSite, logEvent, logMessage } from "@classdojo/log-client";
import { useEventListener, useResizeObserver } from "@web-monorepo/hooks";
import { DDSTabs } from "../DDSTabs/DDSTabs";
import { TranslatedString } from "@web-monorepo/i18next";

// Create a context for managing dialog state
interface DDSDialogContextType {
  open: boolean;
  onOpenChange: (flag: boolean) => void;
}

const DDSDialogContext = React.createContext<DDSDialogContextType>({
  open: false,
  onOpenChange: () => {},
});

interface DDSDialogProps {
  /**
   * The children may be:
   * - Both a Trigger and Content
   * - Or, if you are managing your modal state yourself, just Content
   */
  children:
    | [React.ReactElement<typeof DDSDialogTrigger>, React.ReactElement<typeof DDSDialogContent>]
    | React.ReactElement<typeof DDSDialogContent>;
  "data-name"?: string;
  "event-metadata"?: object;
  experiments?: string[];
  /**
   * Whether the dialog is open.
   */
  open?: boolean;
  /**
   * Whether the dialog is open by default.
   */
  defaultOpen?: boolean;
  /**
   * Callback function to handle the dialog open state change.
   */
  onOpenChange?(open: boolean): void;
  /**
   * The label for the dialog.
   */
  label?: string;
}

const DDSDialogRoot = Dialog.Root;
const DDSDialogTrigger = Dialog.Trigger;

function DDSDialog({ children, ...props }: DDSDialogProps) {
  const { "data-name": dataName, "event-metadata": metadata, experiments, defaultOpen, onOpenChange } = props;

  const [internalOpen, setInternalOpen] = React.useState(defaultOpen ?? false);

  const isControlled = typeof props.open !== "undefined";
  const isOpen = isControlled ? (props.open ?? false) : internalOpen;

  const savedElement = useRef<(typeof document)["activeElement"]>();

  // This is required for refocusing on triggers if they are not
  // specified with explicit DDSDialogTriggers (i.e. bare buttons setting state
  // that triggers the modal)
  useEffect(() => {
    if (typeof isOpen !== "boolean") return;
    if (isOpen) {
      savedElement.current = document.activeElement;
    } else {
      savedElement.current &&
        "focus" in savedElement.current &&
        typeof savedElement.current.focus === "function" &&
        savedElement.current.focus();
    }
  }, [isOpen]);

  // Handle open state changes
  const handleOpenChange = useCallback(
    (newOpen: boolean) => {
      setInternalOpen(newOpen);
      onOpenChange?.(newOpen);
    },
    [setInternalOpen, onOpenChange],
  );

  const dialogContext = React.useMemo(
    () => ({
      open: isOpen,
      onOpenChange: handleOpenChange,
    }),
    [isOpen, handleOpenChange],
  );

  React.useEffect(() => {
    if (!dataName) {
      logMessage("WARN", `${getSite()}.dialog.missingDataName`);
      return;
    }

    if (isOpen)
      logEvent({
        eventName: `${getSite()}.dialog.${dataName}.open`,
        automatedEvent: true,
        metadata,
        experiments,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <DDSDialogContext.Provider value={dialogContext}>
      <DDSDialogRoot open={isOpen} onOpenChange={handleOpenChange}>
        {children}
      </DDSDialogRoot>
    </DDSDialogContext.Provider>
  );
}

function useDialogContext() {
  const context = React.useContext(DDSDialogContext);
  if (context === undefined) {
    throw new Error("useDialogContext must be used within a DDSDialog");
  }
  return context;
}

type DDSDialogHeaderFirst = [React.ReactElement<typeof DDSDialogHeader>, ...React.ReactNode[]];
type BaseDialogContentProps = Omit<Dialog.DialogContentProps, "onClose"> & {
  children: React.ReactElement<typeof DDSTabs> | DDSDialogHeaderFirst;
  /**
   * When true, the dialog will maintain its initial calculated height
   * even when content size changes, preventing layout shifts
   */
  maintainInitialHeight?: boolean;
  contentClassName?: string;
  fullscreen?: true;
  label?: string;
};

type RequireExplicitCloseProps = BaseDialogContentProps & {
  /**
   * Disables closing the dialog on background click, using the escape key, or clicking outside the dialog.
   * Best for modals where forms are being filled out and you don't want to lose the data on an erroneous outside click.
   */
  requireExplicitClose: true;
  onClose?: never;
};

type OnCloseProps = BaseDialogContentProps & {
  requireExplicitClose?: never;
  /**
   * If you're managing your modal state yourself, you can pass in an onClose function to handle the close event for clicking outside the dialog
   */
  onClose: () => void;
};

type DefaultProps = BaseDialogContentProps & {
  requireExplicitClose?: never;
  onClose?: never;
};

type DDSDialogContentProps = RequireExplicitCloseProps | OnCloseProps | DefaultProps;

function isDialogHeader(node: unknown): boolean {
  return React.isValidElement(node) && node.type === DDSDialogHeader;
}

function DDSDialogContent({
  children,
  requireExplicitClose,
  maintainInitialHeight,
  onClose,
  className,
  contentClassName,
  fullscreen,
  label,
  ...rest
}: DDSDialogContentProps) {
  // Runtime check for header
  if (
    Array.isArray(children)
      ? !isDialogHeader(children[0])
      : !Array.isArray(children.props.children) || !isDialogHeader(children.props.children[0])
  ) {
    throw new TypeError(
      "DDSDialogContent requires DDSDialogHeader as its first child (or wrapped with <DDSTabs>). If you don't want to show the header title, you may pass `visuallyHideTitle` to the header.",
    );
  }

  const [initialHeight, setInitialHeight] = React.useState<number | null>(null);
  const contentRef = React.useRef<HTMLDivElement>(null);
  const { onOpenChange } = useDialogContext();

  React.useEffect(() => {
    // Add a small delay to ensure content is rendered before calculating height
    const timer = setTimeout(() => {
      if (maintainInitialHeight && contentRef.current && !initialHeight) {
        const height = contentRef.current.offsetHeight;
        if (height > 0) {
          setInitialHeight(height);
        }
      }
    }, 0);

    return () => clearTimeout(timer);
  }, [maintainInitialHeight, initialHeight, children]);

  const onInteractOutside: DDSDialogContentProps["onInteractOutside"] = (e) => {
    // Focusing inside a modal that is presented by this modal shouldn't close
    // this modal:
    if (e.type !== "dismissableLayer.focusOutside") {
      handleClose(e);
    }
  };

  const handleClose = (e: { stopPropagation: () => void; preventDefault: () => void }) => {
    if (onClose) {
      onClose();
    }

    if (requireExplicitClose) {
      e.preventDefault();
    }
    e.stopPropagation();
  };

  useEventListener(
    "keydown",
    (keyEvent) => {
      if (keyEvent.key === "Escape" && document.activeElement && contentRef.current?.contains(document.activeElement)) {
        keyEvent.stopPropagation();
        keyEvent.preventDefault();

        if (requireExplicitClose) {
          return;
        }

        // Use the context to close the dialog
        if (onClose) {
          onClose();
        } else {
          onOpenChange(false);
        }
      }
    },
    undefined,
    true,
  );

  return (
    <Dialog.Portal>
      <Dialog.Overlay className={classes.overlay}>
        <Dialog.Content
          ref={contentRef}
          aria-label={label}
          className={[classes.container, fullscreen && classes.fullscreenDialog, className].filter(Boolean).join(" ")}
          aria-describedby={undefined}
          onPointerDownOutside={handleClose}
          onInteractOutside={onInteractOutside}
          {...rest}
        >
          <div
            className={[classes.content, contentClassName].filter(Boolean).join(" ")}
            style={maintainInitialHeight && initialHeight ? { minHeight: `${initialHeight}px` } : undefined}
          >
            {children}
          </div>
        </Dialog.Content>
      </Dialog.Overlay>
    </Dialog.Portal>
  );
}

export type DDSDialogHeaderProps = {
  title: TranslatedString | ReactNode;
  /**
   * Whether to hide the title visually. Keeps it in the DOM for accessibility.
   */
  visuallyHideTitle?: boolean;
  className?: string;
  onBack?: () => void;
  removeCloseButton?: boolean;
  /**
   * If you're managing your modal state yourself, you can pass in an onClose function to handle the close event
   */
  onClose?: () => void;
  "data-name"?: string;
  "event-metadata"?: object;
  experiments?: string[];
  children?: ReactNode;
};
function DDSDialogHeader({
  children,
  title,
  className,
  onBack,
  onClose,
  removeCloseButton,
  visuallyHideTitle,
  "data-name": dataName,
  "event-metadata": metadata,
  experiments,
  ...props
}: DDSDialogHeaderProps) {
  const { onOpenChange } = useDialogContext();

  const handleOnClose = () => {
    logEventClose();
    if (onClose) {
      onClose();
    } else {
      onOpenChange(false);
    }
  };

  const logEventClose = () => {
    if (!dataName) return;

    logEvent({
      eventName: `${getSite()}.dialog.${dataName}.close`,
      automatedEvent: true,
      metadata,
      experiments,
    });
  };

  return (
    <>
      <div
        className={[classes.header, children && classes.headerHasChildren, className].filter(Boolean).join(" ")}
        {...props}
      >
        <div
          className={[
            classes.titleBar,
            !onBack && visuallyHideTitle && !removeCloseButton
              ? classes.headerRightAligned
              : classes.headerSpaceBetween,
          ]
            .filter(Boolean)
            .join(" ")}
        >
          {onBack && (
            <div className={classes.headerLeft}>
              <DDSIconButton
                variant="gray"
                size="small"
                onClick={onBack}
                data-name="dialog.back"
                tooltip={{ content: "Back", dataName: "dialog.backTooltip" }}
                aria-label={autoTranslate("Go back")}
              >
                <IconArrowLeft size="s" />
              </DDSIconButton>
            </div>
          )}
          {visuallyHideTitle ? (
            <VisuallyHidden.Root>
              <Dialog.Title className={classes.title}>{title}</Dialog.Title>
            </VisuallyHidden.Root>
          ) : (
            <Dialog.Title className={classes.title}>{title}</Dialog.Title>
          )}
          {removeCloseButton ? (
            <div className={classes.noClose}></div>
          ) : onClose ? (
            <DDSIconButton
              variant="gray"
              size="small"
              onClick={handleOnClose}
              data-name="dialog.close"
              tooltip={{ content: "Close", dataName: "dialog.closeTooltip" }}
              aria-label={autoTranslate("Close")}
            >
              <IconX size="s" />
            </DDSIconButton>
          ) : (
            <Dialog.Close aria-label={autoTranslate("Close")} asChild>
              <DDSIconButton
                variant="gray"
                size="small"
                data-name="dialog.close"
                tooltip={{ content: "Close", dataName: "dialog.closeTooltip" }}
                aria-label={autoTranslate("Close")}
              >
                <IconX size="s" />
              </DDSIconButton>
            </Dialog.Close>
          )}
        </div>
        {children}
      </div>
    </>
  );
}

function DDSDialogBody({ children, className }: { children: ReactNode; className?: string }) {
  const bodyRef = React.useRef<HTMLDivElement>(null);
  const [isScrollable, setIsScrollable] = React.useState(false);

  function updateScrollable() {
    if (bodyRef.current) {
      const hasOverflow = bodyRef.current.scrollHeight > bodyRef.current.clientHeight;
      setIsScrollable(hasOverflow);
    }
  }

  useResizeObserver({
    ref: bodyRef,
    onResize: updateScrollable,
  });

  useLayoutEffect(updateScrollable, [bodyRef]);

  return (
    <div
      ref={bodyRef}
      className={[classes.body, className].filter(Boolean).join(" ")}
      tabIndex={isScrollable ? 0 : undefined}
    >
      {children}
    </div>
  );
}

// TODO: (nice to have) show top shadow on footer if content is scrollable in the modal.
// Will require a resize observer to check if the content is scrollable.
function DDSDialogFooter({ children, className }: { children: ReactNode; className?: string }) {
  return <div className={[classes.footer, className].filter(Boolean).join(" ")}>{children}</div>;
}

function DDSDialogCloseButton({
  children,
  "data-name": dataName,
  onClick,
}: {
  children: ReactNode;
  "data-name": string;
  onClick?: () => void;
}) {
  return (
    <Dialog.Close asChild>
      <DDSButton data-name={dataName} variant="secondary" size="medium" onClick={onClick}>
        {children}
      </DDSButton>
    </Dialog.Close>
  );
}

export {
  DDSDialog,
  DDSDialogTrigger,
  DDSDialogContent,
  DDSDialogHeader,
  DDSDialogFooter,
  DDSDialogCloseButton,
  DDSDialogBody,
};

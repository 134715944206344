import { forwardRef } from "react";
import { useForwardedOrLocalRef, useUniqueIds } from "../../shared/hooks";
import { ClearButton } from "./components";
import type { BaseProps } from "./shared";
import styles from "./InsetLabelField.module.css";

export const Sizes = ["medium", "large"] as const;
export const Variant = "insetLabel";

export interface Props extends BaseProps {
  /**
   * Docs:
   * - {@link https://www.figma.com/design/erhFTAWNMugPVrJGCAWsse/%E2%9D%96-Dojo-Design-System-%E2%80%93-DDS?node-id=781-11468 Figma}
   * - {@link https://components.classdojo.com/?path=/story/components-ddstextfield--inset-label-variant Storybook}
   */
  variant: typeof Variant;
  size: (typeof Sizes)[number];

  /**
   * Not available for insetLabel variant (the label is the placeholder)
   */
  placeholder?: never;
  autoFocus?: boolean;
}

export const InsetLabelField = forwardRef<HTMLInputElement, Props>(
  (
    { ["data-name"]: dataName, disabled, id, label, size = "medium", error, hint, clearButton, onChange, ...rest },
    forwardedRef,
  ) => {
    const ref = useForwardedOrLocalRef(forwardedRef);
    const [labelId, inputId, hintId] = useUniqueIds(["DDSTextField.label", "DDSTextField.input", "DDSTextField.hint"]);
    const className = [styles.textField, styles[size], error ? styles.error : null].join(" ");

    return (
      <label className={className}>
        <div className={styles.inputBox}>
          <span id={labelId} className={styles.label}>
            {label}
          </span>
          <input
            aria-labelledby={labelId}
            className={styles.input}
            data-name={dataName}
            disabled={disabled}
            id={id ?? inputId}
            ref={ref}
            aria-describedby={hintId}
            aria-invalid={error}
            /* insert empty space to make it easier to detect an empty input in the css (more details there) */
            placeholder=" "
            onChange={(e) => onChange?.(e.target.value, e)}
            {...rest}
          />
          {clearButton && <ClearButton className={styles.clearButton} forwardedRef={ref} />}
        </div>
        <span aria-live="polite" id={hintId} className={styles.hint}>
          {hint}
        </span>
      </label>
    );
  },
);

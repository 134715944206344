import { useState } from "react";
import { useSideEffectOnMount } from "@web-monorepo/hooks";
import { localeToString } from "../../pods/i18n";

export const CorsTrack = ({ src, language }: { src: string; language: string }) => {
  const [vttPath, setVttPath] = useState<string | null>(null);

  useSideEffectOnMount(() => {
    fetch(src)
      .then((response) => response.text())
      .then((text) => {
        const vttBlob = new Blob([text], { type: "text/vtt" });
        const vttBlobUrl = URL.createObjectURL(vttBlob);

        setVttPath(vttBlobUrl);
      });
  });

  if (!vttPath) {
    return null;
  }

  return <track default src={vttPath} kind="captions" srcLang={language} label={localeToString[language]} />;
};

/* eslint-disable @web-monorepo/automatic-translations -- dev tooling*/
import { AT } from "@web-monorepo/vite-auto-translate-plugin/runtime";
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useState } from "react";
import * as React from "react";
import Dock from "react-dock";
import { Portal } from "./Portal";
import {
  NessieFunctionComponent,
  NessieComponentProps,
  NessiePropTypeError,
  isNessiePropTypeValidatorSingleProp,
} from "./propTypes";
import BodyText from "./typography/bodyText";
import HeadlineText from "./typography/headlineText";
import LabelText from "./typography/labelText";
import MinutiaeText from "./typography/minutiaeText";

export const nessiePropCheckerWrapper = <T extends NessieComponentProps>(Component: NessieFunctionComponent<T>) => {
  if (Config.nodeEnv !== "development") return Component;
  return (props: T) => {
    let validationError: NessiePropTypeError | undefined;

    if (!props._dangerouslyDisableNessiePropTypes) {
      Object.keys(props).some((prop) => {
        // @ts-expect-error prop is string, but we cannot use `keys`, it will cause other issues
        const validatePropType = Component.nessiePropTypes && Component.nessiePropTypes[prop];
        if (isNessiePropTypeValidatorSingleProp(validatePropType)) {
          validationError = validatePropType(props, prop, Component.displayName);
          return !!validationError;
        }
      });
    }

    const [expanded, setExpanded] = useState(false);

    if (validationError) {
      return (
        <React.Fragment>
          <Portal>
            <div
              onClick={(e: React.MouseEvent) => e.stopPropagation()}
              sx={{ backgroundColor: "dt_content_danger", cursor: "not-allowed", position: "relative" }}
            >
              {!expanded && <SeeMoreButton onClick={() => setExpanded(true)} />}
              {expanded && (
                <Dock position="right" fluid isVisible={true} dimStyle={{ opacity: 0.2 }}>
                  <div sx={{ padding: "dt_m", cursor: "auto", position: "relative" }}>
                    <div
                      onClick={(e: React.MouseEvent) => {
                        e.stopPropagation();
                        setExpanded(false);
                      }}
                      sx={{ cursor: "pointer", display: "inline-block", paddingBottom: "dt_s", position: "relative" }}
                    >
                      <LabelText>Close</LabelText>
                    </div>
                    <ErrorMessage title={validationError.title} description={validationError.description} />
                  </div>
                </Dock>
              )}
            </div>
          </Portal>
          <Component {...props} sx={{ outline: "3px dotted red" }} />
        </React.Fragment>
      );
    }

    return <Component {...props} />;
  };
};

type SeeMoreButtonProps = {
  onClick: () => void;
};

// eslint-disable-next-line react-refresh/only-export-components
const SeeMoreButton = ({ onClick }: SeeMoreButtonProps) => (
  <div
    onClick={(e: React.MouseEvent) => {
      e.stopPropagation();
      onClick();
    }}
    sx={{
      backgroundColor: "dt_content_danger",
      color: "dt_content_light",
      cursor: "pointer",
      zIndex: "9999",
      position: "fixed",
      top: "10px",
      left: "10px",
      border: "dt_card",
      borderRadius: "dt_radius_s",
      paddingLeft: "dt_l",
      paddingRight: "dt_l",
      paddingTop: "dt_xs",
      paddingBottom: "dt_xs",
    }}
  >
    <AT>See more</AT>
  </div>
);

type ErrorMessageProps = {
  title: string;
  description: string;
};

// eslint-disable-next-line react-refresh/only-export-components
const ErrorMessage = (props: ErrorMessageProps) => (
  <div>
    <HeadlineText as="h2" level={2}>
      {props.title}
    </HeadlineText>
    <BodyText level={2} sx={{ whiteSpace: "initial" }}>
      <span dangerouslySetInnerHTML={{ __html: props.description }} />
    </BodyText>

    <div sx={{ marginTop: "dt_l", position: "relative" }}>
      <MinutiaeText kind="disabled" disable-tag-translation>
        Nessie custom prop types:
        <br />
        If you are having issues with a validation that is not doing what you expect you can disable all nessie custom
        validations for this component by adding the prop <strong>'_dangerouslyDisableNessiePropTypes'</strong>. Use
        this only if you consider there is a bug with nessie custom prop types and report it as a Nessie issue.
      </MinutiaeText>
    </div>
  </div>
);

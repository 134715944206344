/* eslint @web-monorepo/prefer-automatic-translations: "error" */

import "../../polyfills/requestAnimationFrame";
import { memo } from "react";
import * as React from "react";
import { CommentInput } from "../../nessie";
import { ThemeUIStyleObject } from "../../nessie/stylingLib";
import LoadingMojo from "../misc/LoadingMojo";
import Comment from "./Comment";
import { autoTranslate } from "@web-monorepo/vite-auto-translate-plugin/runtime";
import { announceToScreenReader } from "@web-monorepo/dds";

type CommentListProps = {
  myId: string;
  myAvatarURL?: string;
  canComment?: boolean;
  renderedComments?: Omit<React.ComponentPropsWithoutRef<typeof Comment>, "myId" | "delete">[] | null;
  createComment: (comment: string) => void;
  deleteComment?: React.ComponentPropsWithoutRef<typeof Comment>["delete"];
  translateComment?: React.ComponentPropsWithoutRef<typeof Comment>["translate"];
};

// eslint-disable-next-line react-refresh/only-export-components
const CommentList = ({
  myId,
  myAvatarURL,
  canComment,
  renderedComments,
  createComment,
  deleteComment,
  translateComment,
}: CommentListProps): JSX.Element => {
  function onSubmit(comment: string) {
    const trimmedComment = comment.trim();
    if (trimmedComment === "") return;

    createComment(trimmedComment);

    announceToScreenReader(autoTranslate("Comment successfully posted"));
  }

  if (!renderedComments) {
    return (
      <div sx={styles.loadingWrapper}>
        <LoadingMojo inline={true} text={autoTranslate("Loading comments")} />
      </div>
    );
  }

  return (
    <div>
      <div>
        {renderedComments.map((comment, index) => (
          <Comment
            key={comment._id}
            {...comment}
            myId={myId}
            delete={deleteComment}
            translate={translateComment}
            shouldFocusOnLoad={index === 0}
          />
        ))}
      </div>
      {canComment && (
        <CommentInput
          disableScreenReader
          placeholder={autoTranslate("Write a comment...")}
          aria-label={autoTranslate("Write a comment...")}
          onSubmit={onSubmit}
          sx={{ padding: "s" }}
          avatarURL={myAvatarURL}
          colorKey={myId}
        />
      )}
    </div>
  );
};

// eslint-disable-next-line react-refresh/only-export-components
export default memo(CommentList);

const styles: Record<string, ThemeUIStyleObject> = {
  loadingWrapper: {
    borderTop: "dt_divider",
    textAlign: "center" as const,
    padding: "l",
    color: "dt_content_tertiary",
  },
};

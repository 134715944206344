import { forwardRef, useState, useCallback, useLayoutEffect } from "react";
import { useForwardedOrLocalRef, useUniqueIds } from "../../shared/hooks";
import classes from "./DDSTextarea.module.css";

type HTMLProps = React.DetailedHTMLProps<React.TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>;
type EventHandlers = Omit<
  {
    [K in keyof HTMLProps as K extends `on${string}` ? K : never]: HTMLProps[K];
  },
  "onChange"
>;
type OtherForwardedProps = Pick<HTMLProps, "id" | "name" | "value" | "disabled">;

export interface Props extends EventHandlers, OtherForwardedProps {
  /** The name will get used for automated product events */
  ["data-name"]: string;

  /** The label text shown above the textarea */
  label: string | React.ReactNode;

  /** Whether the label should be visually hidden, refrain from using unless absolutely necessary */
  visuallyHiddenLabel?: boolean;

  /** Placeholder text shown when textarea is empty */
  placeholder?: string;

  /** Whether the textarea shows error styling */
  error?: boolean;

  /** Helper text shown below the textarea */
  hint?: string | React.ReactNode;

  /** Number of visible text rows */
  rows?: number;

  /** Maximum number of characters allowed */
  maxLength?: number;

  /** Callback fired when the value changes */
  onChange?: (value: string, rawEvent: React.ChangeEvent<HTMLTextAreaElement>) => void;

  /** The value of the textarea */
  value?: string | number | readonly string[];

  /** Whether the textarea should be resizable. Defaults to true */
  enableResize?: boolean;

  /** Whether the textarea should have a border. Defaults to true */
  noBorder?: boolean;

  /** Children to render inside the textarea */
  children?: React.ReactNode;

  maxHeight?: number;
}

/**
 * Docs:
 * - {@link https://components.classdojo.com/?path=/story/components-ddstextarea--basic Storybook}
 */

export const DDSTextarea = forwardRef<HTMLTextAreaElement, Props>(
  (
    {
      ["data-name"]: dataName,
      disabled,
      id,
      label,
      visuallyHiddenLabel,
      error,
      hint,
      placeholder = " ",
      rows = 3,
      maxLength,
      onChange,
      value,
      enableResize = true,
      noBorder = false,
      children,
      maxHeight = 100,
      ...rest
    },
    forwardedRef,
  ) => {
    const ref = useForwardedOrLocalRef(forwardedRef);
    const [labelId, textareaId, hintId] = useUniqueIds(["DDSTextarea.label", "DDSTextarea.input", "DDSTextarea.hint"]);

    const className = [classes.textarea, error ? classes.error : null].filter(Boolean).join(" ");
    const inputClassName = [classes.input, enableResize === false ? classes.noResize : null].filter(Boolean).join(" ");

    const [height, setHeight] = useState<number | null>(null);

    const calculateHeight = useCallback(() => {
      if (!ref.current) return;

      ref.current.style.height = "auto";
      const newHeight = Math.min(ref.current.scrollHeight, maxHeight);
      ref.current.style.height = `${newHeight}px`;
      setHeight(newHeight);
    }, [maxHeight, ref]);

    useLayoutEffect(() => {
      calculateHeight();
    }, [value, calculateHeight]);

    return (
      <div className={className}>
        <label htmlFor={id ?? textareaId} className={visuallyHiddenLabel ? classes.visuallyHiddenLabel : classes.label}>
          {label}
        </label>
        <div className={`${classes.inputBox} ${noBorder ? classes.noBorder : ""}`}>
          <textarea
            aria-labelledby={labelId}
            className={`${inputClassName} ${children ? classes.extraPadding : ""}`}
            data-name={dataName}
            disabled={disabled}
            id={id ?? textareaId}
            placeholder={placeholder}
            ref={ref}
            aria-describedby={hintId}
            aria-invalid={error}
            rows={rows}
            maxLength={maxLength}
            onChange={(e) => {
              onChange?.(e.target.value, e);
              calculateHeight();
            }}
            value={value}
            style={{ height: height ? `${height}px` : "auto" }}
            {...rest}
          />
          {children && <div className={classes.extraPadding}>{children}</div>}
        </div>
        {hint && (
          <span aria-live="polite" id={hintId} className={classes.hint}>
            {hint}
          </span>
        )}
      </div>
    );
  },
);

import "intersection-observer";
import { useEffect, useState, RefObject, useCallback, useRef } from "react";

export function useIntersectionObserverCallback(
  ref: RefObject<Element>,
  callback: (isIntersecting: boolean) => void,
  { root = null, rootMargin = "0px", threshold = 0 }: IntersectionObserverInit = {},
) {
  const value = useRef<boolean | undefined>();
  const wrappedCallback = useCallback(
    // bulk ignoring existing errors
    ([entry]: IntersectionObserverEntry[]) => {
      // Only call callback if the value has changed
      if (value.current !== entry.isIntersecting) {
        value.current = entry.isIntersecting;
        callback(entry.isIntersecting);
      }
    },
    [callback],
  );

  const { current: node } = ref;

  useEffect(() => {
    if (!node) return;

    const observer = new IntersectionObserver(wrappedCallback, {
      root,
      rootMargin,
      threshold,
    });

    observer.observe(node);

    return () => observer.disconnect();
  }, [wrappedCallback, node, root, rootMargin, threshold]);
}

const useIntersectionObserver = (
  ref: RefObject<Element>,
  { root = null, rootMargin = "0px", threshold = 0 }: IntersectionObserverInit = {},
): boolean | undefined => {
  const [isIntersecting, setIsIntersecting] = useState<boolean | undefined>();

  useIntersectionObserverCallback(ref, setIsIntersecting, {
    root,
    rootMargin,
    threshold,
  });

  return isIntersecting;
};

export default useIntersectionObserver;

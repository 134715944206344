import callApi from "@web-monorepo/infra/callApi";
import { useSearchParams } from "react-router-dom";
import { Image } from "../../components/misc/Image";
import { BodyText, Button, HeadlineText } from "../../nessie";
import LogFirstRender from "../../nessie/components/LogFirstRender";
import ToastBanner from "../../nessie/components/ToastBanner";
import { autoTranslate } from "@web-monorepo/vite-auto-translate-plugin/runtime";

const resetPassword = (emailAddress: string) =>
  callApi({ method: "POST", path: "/api/passwordReset", body: { emailAddress } });

const ResetPasswordBlocker = ({
  showToast,
  app,
}: {
  showToast: (elem: JSX.Element) => void;
  app: "teach" | "parent";
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const email = searchParams.get("email") ?? "";

  return (
    <LogFirstRender data-name="reset-passwor-blocker" metadata={{ current_site: app }}>
      <div
        data-name="reset-password-blocker"
        sx={{
          textAlign: "center",
          maxWidth: 370,
        }}
      >
        <HeadlineText as="h2">Reset your password</HeadlineText>
        <Image src="components/passwordResetBlocker/shield.svg" alt="" width={153} />
        {searchParams.get("pwdReset") === "sent" ? (
          <BodyText>We have sent an email to {email} with instructions on how to reset your password.</BodyText>
        ) : (
          <>
            <BodyText>
              As part of our commitment to you, we're always reviewing account security. Help us protect your account by
              resetting your password.
            </BodyText>
            <div
              sx={{ display: "flex", flexDirection: "column", gap: "dt_l", marginTop: "dt_l", marginBottom: "dt_l" }}
            >
              <Button
                data-name="reset-password-blocker-reset-cta"
                width={"100%"}
                size="s"
                onClick={async () => {
                  if (email) {
                    try {
                      await resetPassword(email);
                      setSearchParams({ email, pwdReset: "sent" });
                    } catch (e: any) {
                      if (e.response) {
                        showToast(<ToastBanner text={autoTranslate("Invalid email address")} />);
                        return;
                      }
                      throw e;
                    }
                  }
                }}
              >
                Reset password
              </Button>
            </div>
          </>
        )}
      </div>
    </LogFirstRender>
  );
};

export default ResetPasswordBlocker;

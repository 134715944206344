import { Button, TextField } from "../../nessie";
import { ThemeUIStyleObject } from "../../nessie/stylingLib";

/**
 * Wrapper around our common input in order to add a button on the side.
 */

export type InputWithButtonProps = {
  preventSubmission?: boolean;
  inputProps: React.ComponentPropsWithoutRef<typeof TextField>;
  button: {
    text: string;
    props: React.ComponentPropsWithoutRef<typeof Button> & {
      sx?: ThemeUIStyleObject;
      /**
       * The name will get used for automated product events.
       * @see https://www.notion.so/classdojo/Automatic-Product-Events-for-Web-bfc580f10a914c3ba514e5ec20f8ef9e?pvs=4
       */
      "data-name": string;
    };
  };
};

const InputWithButton = ({ inputProps, button, preventSubmission }: InputWithButtonProps) => {
  return (
    <form
      onSubmit={(e) => {
        if (preventSubmission) return e.preventDefault();
      }}
      sx={{ display: "flex", justifyContent: "flex-start", alignItems: "flex-start", position: "relative" }}
    >
      <TextField {...inputProps} sx={{ flexGrow: 1, padding: "8px 0" }}>
        <Button size="s" type="submit" {...button.props} sx={{ marginLeft: "xs", ...button.props?.sx }}>
          {button.text}
        </Button>
      </TextField>
    </form>
  );
};

export default InputWithButton;

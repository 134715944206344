import capitalize from "lodash/capitalize";
import map from "lodash/map";
import { BodyText, LabelText } from "../../../nessie";
import { Receipt } from "./types";
import { autoTranslate } from "@web-monorepo/vite-auto-translate-plugin/runtime";

type FilteredReceiptHeaderProps = {
  groupedReceipts: Record<string, Pick<Receipt, "time">[]>;
  allReceiptCount?: number;
  isSchoolStory?: boolean;
  selectedTab: string;
  setSelectedTab: (tab: string) => void;
};
const FilteredReceiptHeader = ({
  groupedReceipts,
  allReceiptCount,
  isSchoolStory,
  selectedTab,
  setSelectedTab,
}: FilteredReceiptHeaderProps): JSX.Element => {
  const namesForClass = Object.keys(groupedReceipts)
    .filter((n) => n !== "all")
    .sort();
  const namesForSchool = ["parent", "teacher"];
  const names = isSchoolStory ? namesForSchool : namesForClass;

  const getTranslatedTabName = (name: string) => {
    switch (name) {
      case "parent":
        return autoTranslate("parents");
      case "teacher":
        return autoTranslate("teachers");
      case "student":
        return autoTranslate("students");
      default:
        return autoTranslate("all");
    }
  };

  return (
    <div
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        paddingLeft: "dt_m",
        boxShadow: "dt_shadow_shadezies_small",
        position: "relative",
      }}
    >
      <button
        data-name="filtered_receipt_header:post_receipts_header_all"
        onClick={() => setSelectedTab("all")}
        sx={{
          cursor: "pointer",
          display: "flex",
          flexDirection: "row",
          marginRight: "dt_m",
          position: "relative",
          border: "none",
          backgroundColor: "transparent",
          padding: 0,
          borderBottom: selectedTab === "all" ? "dt_active" : "dt_card",
        }}
      >
        <div
          sx={{
            paddingBottom: "dt_s",
            position: "relative",
          }}
        >
          <BodyText kind={selectedTab === "all" ? "accent" : "primary"}>{capitalize(autoTranslate("all"))}</BodyText>
        </div>
        <LabelText kind="tertiary"> {allReceiptCount}</LabelText>
      </button>
      {map(names, (name) => {
        const receiptCount = groupedReceipts[name] ? groupedReceipts[name].filter((n) => !!n.time).length : 0;
        return (
          <button
            data-name="filtered_receipt_header:post_receipts_header"
            onClick={() => setSelectedTab(name)}
            key={name}
            sx={{
              cursor: "pointer",
              display: "flex",
              flexDirection: "row",
              marginRight: "dt_m",
              position: "relative",
              border: "none",
              backgroundColor: "transparent",
              padding: 0,
              borderBottom: selectedTab === name ? "dt_active" : "dt_card",
            }}
          >
            <div sx={{ position: "relative" }}>
              <BodyText kind={selectedTab === name ? "accent" : "primary"}>
                {capitalize(getTranslatedTabName(name))}
              </BodyText>
            </div>
            <LabelText kind="tertiary"> {receiptCount}</LabelText>
          </button>
        );
      })}
    </div>
  );
};

export default FilteredReceiptHeader;

import { autoTranslate } from "@web-monorepo/vite-auto-translate-plugin/runtime";
import { TranslatedString } from "../../pods/i18n/translate";

const specialCharacters = [
  "{",
  "}",
  "[",
  "]",
  ",",
  ".",
  "<",
  ">",
  ";",
  ":",
  "'",
  '"',
  "?",
  "/",
  "|",
  "\\",
  "`",
  "~",
  "!",
  "@",
  "#",
  "$",
  "%",
  "^",
  "&",
  "*",
  "(",
  ")",
  "_",
  "-",
  "+",
  "=",
  "€",
  "£",
  "¥",
  "•",
];

export const belowMaximumLength = (value: string, maximumLength: number): boolean => value.length <= maximumLength;
export const hasMinimumLength = (value: string, minimumLength: number): boolean => value.length >= minimumLength;
const hasSpecialCharacter = (value: string): boolean => specialCharacters.some((char) => value.includes(char));
const hasNumericCharacter = (value: string): boolean => /[0-9]/.test(value);
export const hasSpecialOrNumericCharacter = (value: string): boolean =>
  hasNumericCharacter(value) || hasSpecialCharacter(value);
export const hasAlphabeticalCharacter = (value: string): boolean => /[a-zA-Z]/.test(value);
export const passwordEqualsEmail = (password: string, emailAddress?: string) => password === emailAddress;

export function isValidPassword(password: string, emailAddress: string): boolean {
  return (
    hasMinimumLength(password, 8) &&
    hasAlphabeticalCharacter(password) &&
    hasSpecialOrNumericCharacter(password) &&
    belowMaximumLength(password, 100) &&
    !passwordEqualsEmail(password, emailAddress)
  );
}

export function getPwTextFieldValidationMessage(
  password: string,
  emailAddress: string,
  commonPassword?: boolean,
): TranslatedString | undefined {
  if (commonPassword) {
    return autoTranslate("This password is too common and not safe to use. Please choose another one.");
  }
  if (password && passwordEqualsEmail(password, emailAddress)) {
    // Defining a new translation dojo.common string because the current one is in teach_web, which web doesn't haves access to.
    // Translation notion says not to interpolate strings.
    return autoTranslate("Password cannot be the same as email address");
  }
  if (!belowMaximumLength(password, 100)) {
    return autoTranslate("__field__ must be maximum __max__ characters long", { field: "Password", max: "100" });
  }
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import type ReactModalType from "react-modal";
import * as Dialog from "@radix-ui/react-dialog";
import { FunctionComponent, MouseEventHandler, useCallback, useEffect, useRef } from "react";
import styles from "./ReactModal.module.css";
import { VisuallyHidden } from "@radix-ui/react-visually-hidden";

type Allowlist<T, K extends keyof T> = {
  [P in K]: T[P];
};

export type ReactModalProps = ReactModalType.Props;
export type ModalOnRequestHide = MouseEventHandler<Element>; // (e: DummyEvent | PointerDownOutsideEvent | InteractOutsideEvent) => void;

type SupportedProps = Allowlist<
  ReactModalType.Props,
  | "isOpen"
  | "data"
  //  | "onRequestClose"
  | "ariaHideApp" // <-- This is probably a bad idea
  | "onAfterOpen"
  | "onAfterClose"
  | "overlayClassName"
  | "className"
  | "shouldCloseOnOverlayClick"
  | "contentLabel"
  | "aria"
  | "children"
  | "style"
> & {
  onRequestClose?: ModalOnRequestHide;
};

type PointerDownOutsideEvent = Parameters<
  NonNullable<React.ComponentProps<typeof Dialog.Content>["onPointerDownOutside"]>
>[0];

type InteractOutsideEvent = Parameters<
  NonNullable<React.ComponentProps<typeof Dialog.Content>["onInteractOutside"]>
>[0];

class DummyEvent extends Event {
  constructor() {
    super("DummyEvent", {});
  }
}

export type OnRequestHideEvent =
  | MouseEvent
  | KeyboardEvent
  | PointerDownOutsideEvent
  | InteractOutsideEvent
  | DummyEvent;

export const ReactModal: FunctionComponent<SupportedProps> = ({
  isOpen,
  data,
  onRequestClose,
  onAfterOpen,
  onAfterClose,
  overlayClassName,
  className,
  shouldCloseOnOverlayClick,
  contentLabel,
  aria,
  style,
  children,
}) => {
  const requestedClose = useRef(false);
  const onEscapeKeyDown = useCallback(
    (e: KeyboardEvent) => {
      onRequestClose?.(e as any);
    },
    [onRequestClose],
  );
  const onPointerDownOutside = useCallback(
    (e: PointerDownOutsideEvent) => {
      if (shouldCloseOnOverlayClick) onRequestClose?.(e as any);
      requestedClose.current = true;
    },
    [shouldCloseOnOverlayClick, onRequestClose],
  );
  /*
  While onInteractOutside is probably generally more correct, we'll go with
  onPointerDownOutside as it is more likely to overlap closely with 
  react-modal behavior:
  const onInteractOutside = useCallback(
    (e: InteractOutsideEvent) => {
      if (shouldCloseOnOverlayClick) onRequestClose?.(e as any);
      requestedClose.current = true;
    },
    [onRequestClose],
  );
  */
  const onOpenChange = useCallback(
    (newIsOpen: boolean) => {
      if (isOpen === true && newIsOpen === false && requestedClose.current === false) {
        onRequestClose?.(new DummyEvent() as any);
      }
      requestedClose.current = false;
    },
    [isOpen, onRequestClose],
  );

  const wasOpen = useRef(isOpen);

  useEffect(() => {
    if (isOpen) onAfterOpen?.();
    else if (wasOpen.current) onAfterClose?.();
    wasOpen.current = isOpen;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen /* onAfterOpen, onAfterClose should not trigger calls on every change */]);

  const dataAttributes = data
    ? Object.fromEntries(Object.entries(data).map(([key, value]) => [`data-${key}`, value]))
    : {};
  const ariaAttributes = aria
    ? Object.fromEntries(Object.entries(aria).map(([key, value]) => [`aria-${key}`, value]))
    : {};

  return (
    <Dialog.Root open={isOpen} onOpenChange={onOpenChange}>
      <Dialog.Portal>
        <Dialog.Overlay className={`${styles.overlay} ${overlayClassName}`} style={style?.overlay} />
        <Dialog.Content
          title={contentLabel}
          aria-label={contentLabel}
          {...dataAttributes}
          {...ariaAttributes}
          onEscapeKeyDown={onEscapeKeyDown}
          onPointerDownOutside={onPointerDownOutside}
          // onInteractOutside={onInteractOutside}
          className={`${styles.container} ${className}`}
          style={style?.content}
        >
          <VisuallyHidden>
            <Dialog.Title>{contentLabel}</Dialog.Title>
          </VisuallyHidden>
          {children}
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

import { FunctionComponent, useLayoutEffect } from "react";
import "../../global.css";

export const DesignSystemVariables: FunctionComponent<React.PropsWithChildren<{ theme?: "light" | "dark" }>> = ({
  theme = "light",
  children,
}) => {
  useLayoutEffect(() => {
    const previousTheme = document.documentElement.getAttribute("data-theme");
    document.documentElement.setAttribute("data-theme", theme);
    return () => {
      if (previousTheme) {
        document.documentElement.setAttribute("data-theme", previousTheme);
      } else {
        document.documentElement.removeAttribute("data-theme");
      }
    };
  }, [theme]);

  return <>{children}</>;
};

import * as React from "react";
import LegalSection from "./LegalSection";
import { AT } from "@web-monorepo/vite-auto-translate-plugin/runtime";

export type LegalSectionWithLanguageProps = {
  entityType?: "teacher" | "parent";
  copy?: React.ComponentPropsWithoutRef<typeof LegalSection>["copy"];
} & Omit<React.ComponentPropsWithoutRef<typeof LegalSection>, "copy">;

// Import this component into projects with access to language files (teach, home, etc);
// If that project needs custom copy for a particular field, they can just pass it in a copy prop
// ie <LegalSectionWithLanguage copy={{creatingAccountExplicit: <div>custom</div>}} />
// Projects without access (external site) can provide all the copy to child component directly
const LegalSectionWithLanguage = ({ entityType = "teacher", ...props }: LegalSectionWithLanguageProps): JSX.Element => {
  const defaultCopy = getDefaultCopy(entityType);
  const copy = props.copy ? { ...defaultCopy, ...props.copy } : defaultCopy;
  return <LegalSection {...props} copy={copy} />;
};

const getDefaultCopy = (entityType: "teacher" | "parent") => ({
  creatingAccountExplicit: (
    <AT>
      I agree to ClassDojo's{" "}
      <a target="_blank" href="//www.classdojo.com/terms">
        Terms of Service
      </a>{" "}
      and{" "}
      <a data-test-name="explicitPrivacyPolicyLink" target="_blank" href="//www.classdojo.com/privacy">
        Privacy Policy
      </a>
    </AT>
  ),
  dataTransfer:
    entityType === "teacher" ? (
      <AT>
        I agree ClassDojo can transfer information generated by my class's use of ClassDojo to the US, for the purpose
        of providing us with the service.
      </AT>
    ) : (
      <AT>
        I agree ClassDojo can transfer information generated by my family’s use of ClassDojo to the US, for the purpose
        of providing us with the service.
      </AT>
    ),
  hide: <AT>Hide</AT>,
  expand: <AT>Expand</AT>,
  creatingAccountImplicit: (
    <AT>
      By signing up, you agree to ClassDojo's{" "}
      <a target="_blank" href="//www.classdojo.com/terms">
        Terms of Service
      </a>{" "}
      and{" "}
      <a target="_blank" href="//www.classdojo.com/privacy">
        Privacy Policy
      </a>
    </AT>
  ),
  howItWorks: <AT>How it works</AT>,
  howItWorksDetails: (
    <AT>
      <div>
        At ClassDojo, we believe privacy is a fundamental right. We’re always working to protect yours. You can read
        more about how at our{" "}
        <a href="https://www.classdojo.com/privacycenter/" target="_blank">
          Privacy Center
        </a>
        .
      </div>
      <div>
        We ask for some personal information to make ClassDojo work for you. We need your consent to transfer this
        information to the US, where ClassDojo is based. You can read more about the{" "}
        <a href="https://www.classdojo.com/transparency/" target="_blank">
          information we collect and why we collect it here
        </a>
        .
      </div>
      <div>
        ClassDojo complies with local privacy regulations, including GDPR in Europe and the UK. Please note that the US
        may not have the same information protection laws as your country, including disclosure to law enforcement
        authorities.
      </div>
    </AT>
  ),
  inControl: <AT>You're in control</AT>,
  inControlDetails: (
    <AT>
      <div>
        ClassDojo never sells any of your personal data with advertisers or marketers. You can read more about this in
        our Privacy Policy.
      </div>
      <div>
        You can withdraw or change your consent at any time.{" "}
        <a href="https://www.classdojo.com/privacy/#object-restrict-or-withdraw-consent" target="_blank">
          Visit our privacy support page for help
        </a>
        .
      </div>
    </AT>
  ),
});

export default LegalSectionWithLanguage;

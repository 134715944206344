import { type ReactNode } from "react";
import { IconX } from "@web-monorepo/dds-icons";
import classes from "./DDSChip.module.css";
import { cva } from "class-variance-authority";
import { DDSBodyText } from "../DDSBodyText/DDSBodyText";

// export const ChipSizes = ["s", "m", "l", "xl"] as const;
// export type ChipSize = (typeof ChipSizes)[number];
export type ChipSize = "m";

// export const ChipColors = ["neutral", "primary", "success", "warning", "danger", "purple", "pink"] as const;
// export type ChipColor = (typeof ChipColors)[number];
export type ChipColor = "primary";

const chipStyles = cva(classes.chip, {
  variants: {
    size: {
      s: classes.sizeS,
      m: classes.sizeM,
      l: classes.sizeL,
      xl: classes.sizeXl,
    },
    color: {
      neutral: classes.colorNeutral,
      primary: classes.colorPrimary,
      success: classes.colorSuccess,
      warning: classes.colorWarning,
      danger: classes.colorDanger,
      purple: classes.colorPurple,
      pink: classes.colorPink,
    },
  },
  defaultVariants: {
    size: "m",
    color: "neutral",
  },
});

export interface DDSChipProps {
  /** The content to display inside the chip */
  text: string;
  /** Optional leading visual (usually an icon) */
  leadingVisual?: ReactNode;
  /** Optional callback when remove button is clicked */
  onRemove?: () => void;
  /** Size variant of the chip */
  size?: ChipSize;
  /** Color variant of the chip */
  color?: ChipColor;
  /** Data-name attribute for product events */
  "data-name": string;
}

/**
 * @wip Still waiting for design feedback
 */
export function DDSChip({
  text,
  leadingVisual,
  onRemove,
  size = "m",
  color = "primary",
  "data-name": dataName,
}: DDSChipProps) {
  return (
    <span className={[chipStyles({ size, color })].filter(Boolean).join(" ")} data-name={dataName}>
      {leadingVisual && <span className={classes.leadingVisual}>{leadingVisual}</span>}
      <span className={classes.content}>
        {
          <DDSBodyText
            level={
              // size === "s" ? 3 :
              2
            }
          >
            {text}
          </DDSBodyText>
        }
      </span>
      {onRemove && (
        <button className={classes.removeButton} onClick={onRemove} aria-label="Remove" type="button">
          <IconX
            size={
              // size === "s" ? "xs" :
              "s"
            }
          />
        </button>
      )}
    </span>
  );
}

import { Root, Track, Range, Thumb } from "@radix-ui/react-slider";
import { RAW_cssValue, ThemeUIStyleObject } from "../../../nessie/stylingLib";

export type BaseSliderProps = {
  ["aria-label"]: string;
  defaultValue?: number[];
  min?: number;
  max?: number;
  step?: number;
  className?: string;
  value?: number[];
  onChange: (value: number[]) => void;
  id?: string;
  focusColor?: string;
};

export const BaseSlider = ({
  defaultValue = [50],
  max = 100,
  min = 0,
  step = 1,
  className,
  onChange,
  value,
  "aria-label": ariaLabel,
  id,
  focusColor = "rgb(0, 146, 229)",
}: BaseSliderProps) => {
  return (
    <Root
      sx={rootStyles}
      defaultValue={defaultValue}
      min={min}
      max={max}
      step={step}
      onValueChange={onChange}
      value={value}
      className={className}
      id={id}
    >
      <Track className="track" sx={trackStyles}>
        <Range sx={rangeStyles} />
      </Track>
      <Thumb
        sx={{
          ...thumbStyles,
          "&:focus": {
            ...(thumbStyles["&:focus"] as object),
            boxShadow: RAW_cssValue(`0 0 0 5px ${focusColor}`),
          },
        }}
        aria-label={ariaLabel}
      />
    </Root>
  );
};

const rootStyles: ThemeUIStyleObject = {
  position: "relative",
  display: "flex",
  alignItems: "center",
  userSelect: "none",
  touchAction: "none",
  width: "100%",

  "&[data-orientation='horizontal']": {
    height: "30px",
  },
  "&[data-orientation='vertical']": {
    flexDirection: "column",
    width: "20px",
    height: "100px",
  },
};

const trackStyles: ThemeUIStyleObject = {
  backgroundColor: "dt_content_tertiary",
  position: "relative",
  flexGrow: 1,
  borderRadius: "dt_radius_s",
  boxShadow: RAW_cssValue("0 0 1px 2px rgb(0 0 0 / 15%)"),

  "&[data-orientation='horizontal']": {
    height: "10px",
  },
  "&[data-orientation='vertical'] ": {
    width: "10px",
  },
};

const rangeStyles: ThemeUIStyleObject = {
  position: "absolute",
  backgroundColor: "dt_background_primary",
  borderRadius: "dt_radius_s",
  height: "100%",
};

const thumbStyles: ThemeUIStyleObject = {
  display: "block",
  width: "30px",
  height: "30px",
  border: RAW_cssValue("8px solid"),
  borderColor: "dt_content_light",
  backgroundColor: "dt_content_primary",
  boxShadow: RAW_cssValue("0 0 1px 2px rgb(0 0 0 / 15%)"),
  borderRadius: "dt_radius_s",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: "dt_content_secondary",
  },
  "&:focus": {
    outline: "none",
    backgroundColor: "dt_content_primary",
    borderColor: "dt_content_light",
    boxShadow: RAW_cssValue("0 0 0 5px rgb(0, 146, 229)"),
  },
};

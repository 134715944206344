import { forwardRef, Ref, useEffect, useState, useCallback } from "react";

interface ISoftFocusTrap {
  children: React.ReactNode;
  isActive: boolean;
  onEscape?: () => void;
}

const FOCUSABLE_ELEMENTS = "button, a, input, textarea, select, details, summary, [tabindex]";

const SoftFocusTrap = forwardRef(({ children, onEscape, isActive }: ISoftFocusTrap, ref: Ref<HTMLElement>) => {
  const [hasTabbed, setHasTabbed] = useState(false);

  useEffect(() => {
    if (isActive) {
      // eslint-disable-next-line @web-monorepo/no-setState-in-useEffect
      setHasTabbed(false);
    }
  }, [isActive]);

  const handleFirstTab = useCallback(
    (event: KeyboardEvent, firstFocusableElement: HTMLButtonElement | HTMLAnchorElement) => {
      if (event.key === "Tab" && !hasTabbed) {
        setHasTabbed(true);
        firstFocusableElement?.focus();
      }
    },
    [hasTabbed],
  );

  useEffect(() => {
    if (typeof ref === "function") {
      return;
    }

    if (!ref || !("current" in ref)) {
      return;
    }

    const element = ref.current;
    if (!element) {
      return;
    }

    const buttonsAndAnchors = Array.from(element.querySelectorAll(FOCUSABLE_ELEMENTS)) as (
      | HTMLButtonElement
      | HTMLAnchorElement
    )[];
    const firstFocusableElement = buttonsAndAnchors[0];
    const lastFocusableElement = buttonsAndAnchors[buttonsAndAnchors.length - 1];

    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape" && onEscape && isActive) {
        // if the focus is inside the trap
        // and the escape key is pressed, call the onEscape function
        // to close the trap
        if (ref.current?.contains(document.activeElement as Node)) {
          onEscape();
        }
      }

      if (isActive) {
        handleFirstTab(event, firstFocusableElement);
      }
    };

    const handleTabForLastFocusableElement = (event: KeyboardEvent) => {
      const keyboardEvent = event as KeyboardEvent;
      if (keyboardEvent.key === "Tab" && !keyboardEvent.shiftKey) {
        keyboardEvent.preventDefault();
        firstFocusableElement.focus();
      }
    };

    const handleTabForFirstFocusableElement = (event: KeyboardEvent) => {
      const keyboardEvent = event as KeyboardEvent;
      if (keyboardEvent.key === "Tab" && keyboardEvent.shiftKey) {
        keyboardEvent.preventDefault();
        lastFocusableElement?.focus();
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    lastFocusableElement?.addEventListener("keydown", handleTabForLastFocusableElement as EventListener);
    firstFocusableElement?.addEventListener("keydown", handleTabForFirstFocusableElement as EventListener);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      lastFocusableElement?.removeEventListener("keydown", handleTabForFirstFocusableElement as EventListener);
      firstFocusableElement?.removeEventListener("keydown", handleTabForFirstFocusableElement as EventListener);
    };
  }, [ref, onEscape, isActive, hasTabbed, handleFirstTab]);

  return <>{children}</>;
});

export default SoftFocusTrap;

import { useState, forwardRef } from "react";
import { DDSButton, DDSTextarea } from "@web-monorepo/dds";
import { autoTranslate } from "@web-monorepo/vite-auto-translate-plugin/runtime";
import { Attachment } from "./PostAttachments";

interface AltTextInputProps {
  attachment: Attachment;
  onAddAltText: (attachmentId: string, altText: string) => void;
  onClose: () => void;
}

export const AltTextInput = forwardRef<HTMLDivElement, AltTextInputProps>(
  ({ attachment, onAddAltText, onClose }, ref) => {
    const [altText, setAltText] = useState<string>(attachment.metadata?.altText || "");

    const handleUpload = async () => {
      if (!attachment._id) return;

      onAddAltText(attachment._id, altText);
      onClose();
    };

    return (
      <div ref={ref} sx={{ paddingTop: "dt_s" }}>
        <DDSTextarea
          data-name="story:alt-text-input"
          label={autoTranslate("Add alt text")}
          value={altText}
          onChange={(value) => setAltText(value)}
          placeholder={autoTranslate("Add description of the image for users who are visually impaired")}
          hint={autoTranslate("Maximum 150 characters")}
          maxLength={150}
          enableResize={false}
        />
        <div sx={{ display: "flex", gap: "dt_s", alignItems: "center", justifyContent: "flex-end", marginTop: "dt_s" }}>
          <DDSButton
            size="small"
            data-name="add-captions"
            disabled={altText.length === 0}
            onClick={handleUpload}
            variant="secondary"
          >
            {autoTranslate("Save alt text")}
          </DDSButton>
        </div>
      </div>
    );
  },
);

import { AT } from "@web-monorepo/vite-auto-translate-plugin/runtime";
import loadingMojoGif from "../../assets/images/components/loading_mojo/loading-mojo.gif";
import { ThemeUIStyleObject } from "../../nessie/stylingLib";

export type LoadingMojoProps = {
  inline?: boolean;
  block?: boolean;
  border?: boolean;
  noOverlay?: boolean;
  /**
   * The name will get used for automated product events.
   * @see https://www.notion.so/classdojo/Automatic-Product-Events-for-Web-bfc580f10a914c3ba514e5ec20f8ef9e?pvs=4
   */
  "data-name"?: string;
  text?: string;
  className?: string;
};

export default function LoadingMojo({
  inline,
  block,
  border,
  "data-name": dataName = "loadingMojo",
  text,
  noOverlay,
  className,
}: LoadingMojoProps) {
  const style: ThemeUIStyleObject = {
    ...(inline ? styles.inline : block ? styles.block : styles.full),
    ...(border && styles.border),
  };

  if (inline && text) {
    // Added this to backward compatibility. The inline mojo without text
    // is used in a number of places and I don't want to break that.
    return (
      <div sx={style} className={className}>
        <div sx={{ justifyContent: "flex-start", flexDirection: "row", display: "flex", flexWrap: "nowrap" }}>
          <div sx={{ flex: "0 0 auto", marginRight: "dt_s", alignSelf: "center" }} role="status" aria-live="assertive">
            <img alt="Content is loading" src={loadingMojoGif} data-name={dataName} />

            <div sx={{ position: "absolute", color: "transparent" }}>
              <AT>Content is loading</AT>
            </div>
          </div>

          <div sx={{ flex: "1 0 0px", alignSelf: "center" }}>
            <span>{text}</span>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      sx={inline ? { display: "inline" } : undefined}
      role={inline ? "alert" : "status"}
      aria-live="assertive"
      className={className}
    >
      {!inline && !block && !noOverlay && <div sx={styles.greyout} />}

      <div sx={{ position: "absolute", color: "transparent" }}>
        <AT>Content is loading</AT>
      </div>

      <img alt="Content is loading" sx={style} src={loadingMojoGif} data-name={dataName} />
    </div>
  );
}

const styles: Record<string, ThemeUIStyleObject> = {
  full: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    margin: "auto",
    zIndex: 100,
  },
  border: {
    boxShadow: "dt_shadow_shadezies_small",
    borderRadius: "dt_radius_s",
    padding: "dt_l",
    backgroundColor: "dt_background_primary",
  },
  inline: {
    display: "inline-block",
  },
  block: {
    display: "block",
    marginBottom: "dt_s",
  },
  greyout: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: "dt_background_primary",
    opacity: "0.4",
    zIndex: 100,
  },
};

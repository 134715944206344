import * as React from "react";
import { Button } from "../../nessie";
import { ThemeUIStyleObject } from "../../nessie/stylingLib";
import { Tooltip } from "../overlay";
import { DDSButton } from "@web-monorepo/dds";

/**
 * Private.
 * Footer abstraction for our modals, render the bottom part of our modal
 * which can be configured in quite a few ways depending the buttons we need
 * and some other design subtleties.
 */

type ComponentWithoutStyle = {
  text: string;
  props: React.ComponentPropsWithoutRef<typeof Button>;
  style?: ThemeUIStyleObject;
};

type FooterProps = {
  tooltip?: ComponentWithoutStyle;
  primaryButton?: ComponentWithoutStyle;
  secondaryButton?: ComponentWithoutStyle;
  tertiaryButton?: ComponentWithoutStyle;
  spaceAroundButtons?: boolean;
  useDDSButtonForPrimary?: boolean;
  useDDSButtonForSecondary?: boolean;
  useDDSButtonForTertiary?: boolean;
};

const Footer = ({
  tooltip,
  primaryButton,
  secondaryButton,
  tertiaryButton,
  spaceAroundButtons,
  useDDSButtonForPrimary = false,
  useDDSButtonForSecondary = false,
  useDDSButtonForTertiary = false,
}: FooterProps): JSX.Element => {
  return (
    <div sx={{ paddingY: "dt_s", paddingX: "dt_l", borderTop: "dt_divider" }}>
      {tooltip && (
        <div sx={tooltipWrapperStyles}>
          <Tooltip
            caret="bottom"
            textColor="dt_content_light"
            backgroundColor="dt_content_secondary"
            sx={{
              padding: "dt_s",
            }}
          >
            {tooltip.text}
          </Tooltip>
        </div>
      )}
      <div
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: spaceAroundButtons ? "space-around" : "flex-end",
          width: "100%",
        }}
      >
        {tertiaryButton && !useDDSButtonForTertiary && (
          <Button
            kind="tertiary"
            size="s"
            sx={{ marginRight: "auto", ...tertiaryButton.style }}
            {...tertiaryButton.props}
          >
            {tertiaryButton.text}
          </Button>
        )}
        {tertiaryButton && useDDSButtonForTertiary && (
          <div sx={{ marginRight: "auto" }}>
            <DDSButton
              onClick={tertiaryButton.props.onClick}
              data-name={tertiaryButton.props["data-name"]}
              size="small"
              variant="tertiary"
            >
              {tertiaryButton.text}
            </DDSButton>
          </div>
        )}
        {secondaryButton && !useDDSButtonForSecondary && (
          <Button kind="tertiary" size="s" sx={{ ...secondaryButton.style }} {...secondaryButton.props}>
            {secondaryButton.text}
          </Button>
        )}
        {secondaryButton && useDDSButtonForSecondary && (
          <DDSButton
            onClick={secondaryButton.props.onClick}
            data-name={secondaryButton.props["data-name"]}
            size="small"
            variant="tertiary"
          >
            {secondaryButton.text}
          </DDSButton>
        )}
        {primaryButton && !useDDSButtonForPrimary && (
          <Button sx={{ marginLeft: "dt_xxl", ...primaryButton.style }} size="s" {...primaryButton.props}>
            {primaryButton.text}
          </Button>
        )}
        {primaryButton && useDDSButtonForPrimary && (
          <div sx={{ marginLeft: "dt_xxl" }}>
            <DDSButton
              onClick={primaryButton.props.onClick}
              data-name={primaryButton.props["data-name"]}
              size="small"
              variant="primary"
              disabled={primaryButton.props.disabled}
            >
              {primaryButton.text}
            </DDSButton>
          </div>
        )}
      </div>
    </div>
  );
};

Footer.displayName = "StandardFooter";

export default Footer;

const tooltipWrapperStyles = {
  height: 0,
  textAlign: "center" as const,
  position: "relative" as const,
  top: "-5.7rem",
};

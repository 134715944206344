import * as React from "react";
import { LabelText } from "../../nessie/components/typography/labelText";
import { ThemeUIStyleObject } from "../../nessie/stylingLib";
import { Tile } from "./Tile";

/*
  Note - each child will be wrapped and padded.
  This enables "multi-section" sidebars, such as the parent side
  "connected classes" tile and the teacher site "approve teachers" tile
*/

type SidebarTileProps = {
  header: React.ReactNode;
  rightContent?: React.ReactNode;
  headerStyle?: ThemeUIStyleObject;
  sections?: React.ReactNode[];
  sectionStyle?: ThemeUIStyleObject;
  children?: React.ReactNode;
};

export const SidebarTile = ({
  header,
  headerStyle,
  sections,
  sectionStyle,
  children,
  rightContent,
}: SidebarTileProps) => {
  sections = sections || [children];

  return (
    <Tile
      sx={{
        a: {
          color: "dt_content_accent",
        },
      }}
      role="group"
      aria-label={`${header}`}
    >
      <div sx={{ display: "flex", alignItems: "center" }}>
        <LabelText level={2} as="h2" sx={{ ...styles.header, ...headerStyle }}>
          {header}
        </LabelText>
        {rightContent && rightContent}
      </div>
      {sections.map((section, i) => (
        <div key={`child${i}`} sx={{ ...styles.section, ...sectionStyle }}>
          {section}
        </div>
      ))}
    </Tile>
  );
};

const styles: Record<string, ThemeUIStyleObject> = {
  header: {
    padding: "dt_s",
    paddingLeft: "dt_m",
    paddingRight: "0px",
  },
  section: {
    borderTop: "dt_divider",
    padding: "dt_s",
    paddingX: "dt_m",
  },
};

import { BodyText, ListItem, PasswordTextField } from "../../nessie";
import { CheckmarkIcon, CloseIcon } from "../../nessie/icons";
import { useCallback, useState } from "react";
import {
  getPwTextFieldValidationMessage,
  hasAlphabeticalCharacter,
  hasMinimumLength,
  hasSpecialOrNumericCharacter,
  isValidPassword,
} from "./pwValidators";
import { AT, autoTranslate } from "@web-monorepo/vite-auto-translate-plugin/runtime";

type PasswordRequirementsProps = {
  hasEightCharacters: boolean;
  includesAlphabeticalCharacter: boolean;
  includesSpecialOrNumericCharacter: boolean;
  highlightRequirements: boolean;
};

const PasswordRequirements = ({
  hasEightCharacters,
  includesAlphabeticalCharacter,
  includesSpecialOrNumericCharacter,
  highlightRequirements,
}: PasswordRequirementsProps) => {
  const minimumLengthState = hasEightCharacters ? "valid" : highlightRequirements ? "invalid" : "pending";
  const alphabeticState = includesAlphabeticalCharacter ? "valid" : highlightRequirements ? "invalid" : "pending";
  const numericSpecialState = includesSpecialOrNumericCharacter
    ? "valid"
    : highlightRequirements
      ? "invalid"
      : "pending";

  return (
    <div sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start", marginTop: "dt_xs" }}>
      <BodyText sx={{ fontWeight: "dt_bold", color: "dt_content_secondary" }} level={2}>
        Password must:
      </BodyText>
      <PasswordRequirement type="minimum-length" state={minimumLengthState} />
      <PasswordRequirement type="includes-alphabetic" state={alphabeticState} />
      <PasswordRequirement type="includes-numeric-or-special" state={numericSpecialState} />
    </div>
  );
};

type PasswordRequirementProps = {
  state: "valid" | "pending" | "invalid";
  type: "minimum-length" | "includes-alphabetic" | "includes-numeric-or-special";
};

const PasswordRequirement = ({ state, type }: PasswordRequirementProps) => {
  const checkIcon = <CheckmarkIcon size="xs" color="dt_content_success" />;
  const xIcon = <CloseIcon size="xs" color="dt_content_danger" />;
  const emptyIcon = <CheckmarkIcon size="xs" color="dt_content_disabled" />;
  const iconMap = {
    valid: checkIcon,
    pending: emptyIcon,
    invalid: xIcon,
  };
  const colorMap = {
    valid: "dt_content_success",
    pending: "dt_content_secondary",
    invalid: "dt_content_danger",
  };
  const icon = iconMap[state];
  const translationStrings = {
    "minimum-length": <AT>Be at least 8 characters</AT>,
    "includes-alphabetic": <AT>Include 1 alphabetical character</AT>,
    "includes-numeric-or-special": <AT>Include 1 number or special character</AT>,
    "not-same-as-email": <AT>Not be the same as email</AT>,
  } as const;

  return (
    <ListItem
      leftAccessory={icon}
      title={
        <BodyText level={2} sx={{ color: colorMap[state] }}>
          {translationStrings[type]}
        </BodyText>
      }
    />
  );
};

const CreatePasswordField = ({
  onChange,
  onFocus,
  password,
  emailInputValue,
  isCommonPassword,
}: {
  onChange: (value: string) => void;
  onFocus?: () => void;
  emailInputValue: string;
  password: string;
  isCommonPassword?: boolean;
}) => {
  const [highlightRequirements, setHighlightrequirements] = useState(false);
  const [showRequirements, setShowRequirements] = useState(false);
  const isValid = useCallback(() => {
    const valid = isValidPassword(password, emailInputValue);
    return valid;
  }, [password, emailInputValue]);

  // pwTextFieldValidationMessage is for error that aren't included in PasswordRequirements
  const pwTextFieldValidationMessage = getPwTextFieldValidationMessage(password, emailInputValue, isCommonPassword);
  return (
    <div>
      <PasswordTextField
        data-name="userInfoPasswordInput"
        onChange={onChange}
        onBlur={() => {
          setHighlightrequirements(true);
        }}
        onFocus={() => {
          typeof onFocus === "function" && onFocus();
          setShowRequirements(true);
        }}
        value={password}
        valid={!pwTextFieldValidationMessage && isValid()}
        validationMessage={pwTextFieldValidationMessage}
        placeholder={autoTranslate("Password")}
        aria-label={autoTranslate("Password")}
      />
      {showRequirements && (
        <PasswordRequirements
          hasEightCharacters={hasMinimumLength(password, 8)}
          includesAlphabeticalCharacter={hasAlphabeticalCharacter(password)}
          includesSpecialOrNumericCharacter={hasSpecialOrNumericCharacter(password)}
          highlightRequirements={highlightRequirements}
        />
      )}
    </div>
  );
};

export default CreatePasswordField;

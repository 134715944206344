"use client";

import * as React from "react";
import { IconChevronLeft, IconChevronRight } from "@web-monorepo/dds-icons";
import { DayPicker } from "react-day-picker";
import styles from "./DDSDayPicker.module.css";

export type DateRange = { from?: string; to?: string };

type InnerDayPickerProps = React.ComponentProps<typeof DayPicker>;
export type DayPickerProps = Omit<InnerDayPickerProps, "selected" | "onSelect" | "mode"> & {
  mode: "single" | "range" | "multiple";
} & (
    | {
        mode: "single";
        selected?: string;
        onSelect?: (selected: string | undefined) => void;
      }
    | {
        mode: "range";
        selected?: { from?: string; to?: string };
        onSelect?: (selected: DateRange) => void;
      }
    | {
        mode: "multiple";
        selected?: string[];
        onSelect?: (selected: string[]) => void;
      }
  );

function browserDateToISODateString(input: Date): string {
  const utcDate = new Date(Date.UTC(input.getFullYear(), input.getMonth(), input.getDate()));
  return utcDate.toISOString().substring(0, 10);
}

function isoDateStringToBrowserDate(input: string): Date {
  return new Date(`${input}T00:00:00`);
}

function DDSDayPicker({
  className,
  classNames,
  showOutsideDays = true,
  mode,
  onSelect,
  selected,
  ...props
}: DayPickerProps) {
  // Shared props for all modes
  const sharedProps = {
    showOutsideDays,
    className,
    classNames: {
      months: styles.months,
      month: styles.month,
      caption: styles.caption,
      caption_label: styles.captionLabel,
      nav: styles.nav,
      nav_button: ` ${styles.navButton} `,
      nav_button_previous: `${styles.navButtonPrevious}`,
      nav_button_next: `${styles.navButtonNext}`,
      table: styles.table,
      head_row: styles.headRow,
      head_cell: styles.headCell,
      row: styles.row,
      cell: styles.cell,
      day: `${styles.day}`,
      day_range_start: styles.dayRangeStart,
      day_range_end: styles.dayRangeEnd,
      day_selected: styles.daySelected,
      day_today: styles.dayToday,
      day_outside: styles.dayOutside,
      day_disabled: styles.dayDisabled,
      day_range_middle: styles.dayRangeMiddle,
      day_hidden: styles.dayHidden,
      ...classNames,
    },
    components: {
      IconLeft: () => <IconChevronLeft />,
      IconRight: () => <IconChevronRight />,
    },
    formatters: {
      formatCaption: (month: Date) => {
        return month.toLocaleDateString(undefined, { month: "short", year: "numeric" });
      },
      formatWeekdayName: (weekday: Date) => {
        return weekday.toLocaleDateString(undefined, { weekday: "short" });
      },
    },
    ...props,
  };

  let modeProps;
  switch (mode) {
    case "single": {
      modeProps = {
        mode: "single" as const,
        selected: selected ? isoDateStringToBrowserDate(selected as string) : undefined,
        onSelect: onSelect
          ? (date: Date | undefined) => onSelect(date ? browserDateToISODateString(date) : undefined)
          : undefined,
      };
      break;
    }
    case "range": {
      const rangeSelected = selected as { from?: string; to?: string } | undefined;
      modeProps = {
        mode: "range" as const,
        selected: rangeSelected
          ? {
              from: rangeSelected.from ? isoDateStringToBrowserDate(rangeSelected.from) : undefined,
              to: rangeSelected.to ? isoDateStringToBrowserDate(rangeSelected.to) : undefined,
            }
          : undefined,
        onSelect: onSelect
          ? (range: { from?: Date; to?: Date } | undefined) =>
              onSelect(
                range
                  ? {
                      from: range.from ? browserDateToISODateString(range.from) : undefined,
                      to: range.to ? browserDateToISODateString(range.to) : undefined,
                    }
                  : {},
              )
          : undefined,
      };
      break;
    }
    case "multiple": {
      modeProps = {
        mode: "multiple" as const,
        selected: selected ? (selected as string[]).map(isoDateStringToBrowserDate) : undefined,
        onSelect: onSelect
          ? (dates: Date[] | undefined) => onSelect(dates ? dates.map(browserDateToISODateString) : [])
          : undefined,
      };
      break;
    }
  }

  return <DayPicker {...sharedProps} {...modeProps} />;
}
DDSDayPicker.displayName = "Calendar";

export { DDSDayPicker };

import capitalize from "lodash/capitalize";
import groupBy from "lodash/groupBy";
import sortBy from "lodash/sortBy";
import { useState } from "react";
import * as React from "react";
import { BodyText, LabelText, Space } from "../../../nessie";
import { ChevronUpIcon, ChevronDownIcon } from "../../../nessie/icons";
import { UnstyledButton } from "../../buttons";
import StandardModal from "../../modals/Standard";
import FilteredReceiptHeader from "./FilteredReceiptHeader";
import FilteredReceiptItem from "./FilteredReceiptItem";
import { Receipt } from "./types";
import { autoTranslate } from "@web-monorepo/vite-auto-translate-plugin/runtime";

type FilteredReadsReceiptsModalProps = {
  title: React.ComponentPropsWithoutRef<typeof StandardModal>["title"];
  close: React.ComponentPropsWithoutRef<typeof StandardModal>["onRequestHide"];
  receipts?: Receipt[];
  /**
   * The name will get used for automated product events.
   * @see https://www.notion.so/classdojo/Automatic-Product-Events-for-Web-bfc580f10a914c3ba514e5ec20f8ef9e?pvs=4
   */
  "data-name"?: React.ComponentPropsWithoutRef<typeof StandardModal>["data-name"];
  isSchoolStory?: boolean;
};

type UserType = "all" | "parent" | "student" | "teacher";

function isValidUserType(value: string): value is UserType {
  return value === "all" || value === "parent" || value == "student" || value === "teacher";
}

const FilteredReadsReceiptsModal = ({
  receipts,
  close,
  title,
  isSchoolStory,
  "data-name": dataName,
}: FilteredReadsReceiptsModalProps): JSX.Element => {
  const [selectedTab, setSelectedTab] = useState("all");

  const sortedReceipts = sortBy(receipts, "time").reverse();
  const groupedReceipts = groupBy(sortedReceipts, "entityType");
  groupedReceipts.all = sortedReceipts;

  const allReadCount = sortedReceipts.filter((n) => !!n.time).length;

  return (
    <StandardModal
      small={true}
      title={title}
      loading={!receipts}
      onRequestHide={close}
      contentStyle={styles.modal}
      data-name={dataName}
      headerStyle={{
        border: "none",
      }}
    >
      <FilteredReceiptHeader
        groupedReceipts={groupedReceipts}
        allReceiptCount={allReadCount}
        isSchoolStory={isSchoolStory}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
      />
      {isValidUserType(selectedTab) ? (
        <ReceiptSection
          receipts={groupedReceipts[selectedTab] || []}
          userType={selectedTab}
          isSchoolStory={isSchoolStory}
        />
      ) : null}
    </StandardModal>
  );
};

export default FilteredReadsReceiptsModal;

type ReceiptSectionProps = {
  receipts: Receipt[];
  userType: UserType;
  isSchoolStory?: boolean;
};

const ReceiptSection = ({ receipts, userType, isSchoolStory }: ReceiptSectionProps): JSX.Element => {
  const seenReceipts = receipts.filter((n) => !!n.time);
  const notSeenReceipts = sortBy(
    receipts.filter((n) => !n.time),
    ["entityName"],
  );
  const [seenSectionOpen, setSeenSectionOpen] = useState(seenReceipts.length > 0);
  const [notSeenSectionOpen, setNotSeenSectionOpen] = useState(notSeenReceipts.length > 0);

  const emptyTextDictionary = {
    all: {
      emptySeenText: autoTranslate("No one has seen this"),
      emptyNotSeenText: autoTranslate("Everyone has seen this"),
    },
    parent: {
      emptySeenText: autoTranslate("No parents have seen this"),
      emptyNotSeenText: autoTranslate("All parents have seen this"),
    },
    student: {
      emptySeenText: autoTranslate("No students have seen this"),
      emptyNotSeenText: autoTranslate("All students have seen this"),
    },
    teacher: {
      emptySeenText: autoTranslate("No teachers have seen this"),
      emptyNotSeenText: autoTranslate("All teachers have seen this"),
    },
  };

  const { emptySeenText, emptyNotSeenText } = emptyTextDictionary[userType];

  const toggleSeen = (e: React.SyntheticEvent) => {
    e.preventDefault();
    setSeenSectionOpen(!seenSectionOpen);
  };

  const toggleNotSeen = (e: React.SyntheticEvent) => {
    e.preventDefault();
    setNotSeenSectionOpen(!notSeenSectionOpen);
  };

  return (
    <div sx={{ paddingTop: "dt_m", paddingLeft: "dt_m", position: "relative" }}>
      <div sx={{ display: "flex", flex: 1, flexDirection: "column", position: "relative" }}>
        {!isSchoolStory && (
          <>
            <div
              sx={{
                display: "flex",
                flex: 1,
                flexDirection: "row",
                alignItems: "center",
                paddingRight: "dt_l",
                position: "relative",
              }}
            >
              <div sx={{ flexGrow: 1, position: "relative" }}>
                <BodyText>{capitalize(autoTranslate("seen"))}</BodyText>
              </div>
              <UnstyledButton
                onClick={toggleSeen}
                sx={{ lineHeight: "0" }}
                data-name="filtered_reads_receipt:seen_section_toggle"
              >
                {seenSectionOpen ? (
                  <ChevronDownIcon color="dt_content_tertiary" />
                ) : (
                  <ChevronUpIcon color="dt_content_tertiary" />
                )}
              </UnstyledButton>
            </div>
            <Space size="s" />
          </>
        )}
        <div sx={isSchoolStory ? {} : { display: seenSectionOpen ? "block" : "none" }}>
          <SeenReceiptSection seenReceipts={seenReceipts} emptySeenText={emptySeenText} />
        </div>
      </div>
      <div sx={{ display: "flex", flex: 1, flexDirection: "column", position: "relative" }}>
        {!isSchoolStory && (
          <>
            <div
              sx={{
                display: "flex",
                flex: 1,
                flexDirection: "row",
                alignItems: "center",
                paddingRight: "dt_l",
                position: "relative",
              }}
            >
              <div sx={{ flexGrow: 1, position: "relative" }}>
                <BodyText>{capitalize(autoTranslate("not seen"))}</BodyText>
              </div>
              <UnstyledButton
                onClick={toggleNotSeen}
                sx={{ lineHeight: "0" }}
                data-name="filtered_reads_receipt:not_seen_section_toggle"
              >
                {notSeenSectionOpen ? (
                  <ChevronDownIcon color="dt_content_tertiary" />
                ) : (
                  <ChevronUpIcon color="dt_content_tertiary" />
                )}
              </UnstyledButton>
            </div>
            <Space size="s" />
            <div sx={{ display: notSeenSectionOpen ? "block" : "none" }}>
              <NotSeenReceiptSection notSeenReceipts={notSeenReceipts} emptyNotSeenText={emptyNotSeenText} />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

type NotSeenReceiptSectionProps = {
  notSeenReceipts: Receipt[];
  emptyNotSeenText: string;
};

const NotSeenReceiptSection = ({ notSeenReceipts, emptyNotSeenText }: NotSeenReceiptSectionProps): JSX.Element => {
  return notSeenReceipts.length === 0 ? (
    <>
      <LabelText kind="tertiary">{emptyNotSeenText}</LabelText>
      <Space size="m" />
    </>
  ) : (
    <div sx={{ opacity: "0.5" }}>
      {notSeenReceipts.map((receipt) => {
        return <FilteredReceiptItem key={receipt.entityId} receipt={receipt} />;
      })}
    </div>
  );
};

type SeenReceiptSectionProps = {
  seenReceipts: Receipt[];
  emptySeenText: string;
};

const SeenReceiptSection = ({ seenReceipts, emptySeenText }: SeenReceiptSectionProps): JSX.Element => {
  return seenReceipts.length === 0 ? (
    <>
      <LabelText kind="tertiary">{emptySeenText}</LabelText>
      <Space size="m" />
    </>
  ) : (
    <div>
      {seenReceipts.map((receipt) => {
        return <FilteredReceiptItem key={receipt.entityId} receipt={receipt} />;
      })}
    </div>
  );
};

const styles = {
  modal: {
    padding: "0",
  } as const,
  receipt: {
    padding: "0.8rem",
  },
};

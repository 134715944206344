import { useEffect, useState } from "react";
import { CSSTransition } from "react-transition-group";
import { GlobalCSS, keyframes } from "../../nessie/stylingLib";

type AnimationWrapperType = {
  type: "bounceAnimation" | "slide";
  animate: boolean;
  isVisible: boolean;
  children: React.ReactElement;
  delay?: number;
};

const BOUNCE_ENTER_TIME = 1300;
const BOUNCE_EXIT_TIME = 100;
const DELAY_TIME = 800;

const animationTimeout = {
  bounceAnimation: {
    delay: DELAY_TIME,
    enter: BOUNCE_ENTER_TIME,
    exit: BOUNCE_EXIT_TIME,
  },
  slide: {
    delay: DELAY_TIME,
    enter: 300,
    exit: 300,
  },
};

export const AnimationWrapper = ({ type, animate, children, isVisible, delay }: AnimationWrapperType) => {
  const [startAnimation, setStartAnimation] = useState(animate ? false : isVisible);
  const enter = animationTimeout[type].enter;
  const exit = animationTimeout[type].exit;
  const delayOverride = delay || animationTimeout[type].delay;

  useEffect(() => {
    if (animate && isVisible) {
      setTimeout(() => {
        if (animate && isVisible) {
          // bulk ignoring existing errors
          // eslint-disable-next-line @web-monorepo/no-setState-in-useEffect
          setStartAnimation(true);
        }
      }, delayOverride);
    }
  }, [animate, isVisible, delayOverride]);

  if (animate) {
    return (
      <CSSTransition classNames={type} in={startAnimation && isVisible} unmountOnExit timeout={{ enter, exit }}>
        {children}
      </CSSTransition>
    );
  }
  return isVisible ? children : null;
};

export const AnimationGlobalStyles = () => {
  const popupBounce = keyframes`
    0% { transform: matrix3d(0.5, 0, 0, 0, 0, 0.5, 0, 0, 0, 0, 1, 0, 0, 25, 0, 1); opacity: 0.5}
    1.3% { transform: matrix3d(0.563, 0, 0, 0, 0, 0.563, 0, 0, 0, 0, 1, 0, 0, 20.348, 0, 1); opacity: 0.55}
    2.6% { transform: matrix3d(0.646, 0, 0, 0, 0, 0.646, 0, 0, 0, 0, 1, 0, 0, 16.63, 0, 1); opacity: 0.6}
    3.4% { transform: matrix3d(0.703, 0, 0, 0, 0, 0.703, 0, 0, 0, 0, 1, 0, 0, 14.569, 0, 1); opacity: 0.65}
    5.16% { transform: matrix3d(0.833, 0, 0, 0, 0, 0.833, 0, 0, 0, 0, 1, 0, 0, 10.487, 0, 1); opacity: 0.7}
    6.81% { transform: matrix3d(0.946, 0, 0, 0, 0, 0.946, 0, 0, 0, 0, 1, 0, 0, 7.222, 0, 1); opacity: 0.75}
    7.76% { transform: matrix3d(1.004, 0, 0, 0, 0, 1.004, 0, 0, 0, 0, 1, 0, 0, 5.645, 0, 1); opacity: 0.8}
    10.21% { transform: matrix3d(1.113, 0, 0, 0, 0, 1.113, 0, 0, 0, 0, 1, 0, 0, 2.645, 0, 1); opacity: 0.85}
    10.31% { transform: matrix3d(1.116, 0, 0, 0, 0, 1.116, 0, 0, 0, 0, 1, 0, 0, 2.554, 0, 1); opacity: 0.9}
    13.61% { transform: matrix3d(1.166, 0, 0, 0, 0, 1.166, 0, 0, 0, 0, 1, 0, 0, 0.6, 0, 1); opacity: 0.95}
    15.47% { transform: matrix3d(1.154, 0, 0, 0, 0, 1.154, 0, 0, 0, 0, 1, 0, 0, 0.151, 0, 1); opacity: 1}
    17.52% { transform: matrix3d(1.12, 0, 0, 0, 0, 1.12, 0, 0, 0, 0, 1, 0, 0, -0.063, 0, 1); opacity: 1}
    20.62% { transform: matrix3d(1.05, 0, 0, 0, 0, 1.05, 0, 0, 0, 0, 1, 0, 0, -0.126, 0, 1); opacity: 1}
    21.32% { transform: matrix3d(1.035, 0, 0, 0, 0, 1.035, 0, 0, 0, 0, 1, 0, 0, -0.122, 0, 1); opacity: 1}
    25.23% { transform: matrix3d(0.969, 0, 0, 0, 0, 0.969, 0, 0, 0, 0, 1, 0, 0, -0.075, 0, 1); opacity: 1}
    29.03% { transform: matrix3d(0.948, 0, 0, 0, 0, 0.948, 0, 0, 0, 0, 1, 0, 0, -0.037, 0, 1); opacity: 1}
    36.74% { transform: matrix3d(0.989, 0, 0, 0, 0, 0.989, 0, 0, 0, 0, 1, 0, 0, -0.006, 0, 1); opacity: 1}
    44.44% { transform: matrix3d(1.016, 0, 0, 0, 0, 1.016, 0, 0, 0, 0, 1, 0, 0, -0.001, 0, 1); opacity: 1}
    50% { transform: matrix3d(1.008, 0, 0, 0, 0, 1.008, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); opacity: 1}
    59.86% { transform: matrix3d(0.995, 0, 0, 0, 0, 0.995, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); opacity: 1}
    75.28% { transform: matrix3d(1.002, 0, 0, 0, 0, 1.002, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); opacity: 1}
    90.69% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); opacity: 1}
    100% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); opacity: 1}
  `;
  return (
    <GlobalCSS
      styles={{
        ".slide-enter": {
          transform: "translateX(calc(100%))",
        },
        ".slide-enter-active": {
          transform: "translateX(0)",
          transition: "transform 300ms",
          transitionTimingFunction: "cubic-bezier(0.7, -0.4, 0.4, 1.4)",
        },
        ".slide-exit": {
          transform: "translateX(0)",
        },
        ".slide-exit-active": {
          transform: "translateX(calc(100%))",
          transition: "transform 300ms",
          transitionTimingFunction: "cubic-bezier(0.7, -0.4, 0.4, 1.4)",
        },
        ".bounceAnimation-enter": {
          animation: `${popupBounce} ${BOUNCE_ENTER_TIME}ms linear both`,
        },
        ".bounceAnimation-exit": {
          transition: `opacity ${BOUNCE_EXIT_TIME}ms ease-in-out`,
          WebkitTransition: `opacity ${BOUNCE_EXIT_TIME}ms ease-in-out`,
          opacity: 1,
        },
        ".bounceAnimation-exit.bounceAnimation-exit-active": {
          opacity: 0,
        },
      }}
    />
  );
};

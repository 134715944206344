import * as Popover from "@radix-ui/react-popover";
import { type ReactNode } from "react";
import classes from "./DDSPopover.module.css";

type DDSPopoverRootProps = {
  children: ReactNode;
  /**
   * Whether the popover is open, if you want to use this as a controlled component. Your trigger must use the DDSPopoverAnchor component.
   */
  open?: boolean;
  /**
   * Callback function to handle when the popover is opened or closed when using this as a controlled component
   */
  onOpenChange?(open: boolean): void;
  /**
   * Whether the popover is open by default
   */
  defaultOpen?: boolean;
  /**
   * Whether the popover is modal
   * @default false
   */
  modal?: boolean;
};

const DDSPopoverRoot = ({ children, ...props }: DDSPopoverRootProps) => {
  return <Popover.Root {...props}>{children}</Popover.Root>;
};

const DDSPopoverTrigger = Popover.Trigger;
const DDSPopoverAnchor = Popover.Anchor;

type DDSPopoverContentProps = {
  children: ReactNode;
  /**
   * Alignment of the popover content
   * @default "center"
   */
  align?: "start" | "center" | "end";
  /**
   * Offset of the popover content
   * @default 0
   */
  sideOffset?: number;
  /**
   * Optional side to render the popover on
   * @default "bottom"
   */
  side?: "top" | "right" | "bottom" | "left";
  /**
   * Whether to show the arrow
   * @default true
   */
  showArrow?: boolean;
  /**
   * Whether to avoid collisions with boundary edges
   * @default true
   */
  avoidCollisions?: boolean;
} & Omit<Popover.PopoverContentProps, "side">;

function DDSPopoverContent({
  children,
  align = "center",
  sideOffset = 0,
  side = "bottom",
  className,
  showArrow = true,
  avoidCollisions = true,
  ...props
}: DDSPopoverContentProps) {
  return (
    <Popover.Portal>
      <Popover.Content
        align={align}
        sideOffset={sideOffset}
        side={side}
        avoidCollisions={avoidCollisions}
        className={`${classes.content} ${className || ""}`}
        {...props}
      >
        {children}
        {showArrow && <Popover.Arrow className={classes.arrow} />}
      </Popover.Content>
    </Popover.Portal>
  );
}

export { DDSPopoverRoot as DDSPopover, DDSPopoverTrigger, DDSPopoverContent, DDSPopoverAnchor };

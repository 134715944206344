/* eslint @web-monorepo/prefer-automatic-translations: "error" */
import uniqueId from "lodash/uniqueId";
import * as React from "react";
import { UnstyledButton } from "../../components/buttons";
import ProfilePicture from "../../components/misc/ProfilePicture";
import { SendIcon } from "../icons";
import { TextAreaProps } from "./TextArea";
import { announceToScreenReader, DDSTextarea } from "@web-monorepo/dds";
import { autoTranslate } from "@web-monorepo/vite-auto-translate-plugin/runtime";

type CommentInputProps = {
  onSubmit: (value: string) => void;
  avatarURL?: string;
  className?: string;
  colorKey?: string;
  disableScreenReader?: boolean;
} & Omit<TextAreaProps, "onSubmit">;

export type CommentInputRef = {
  focus: () => void;
};

export const CommentInput = ({
  onSubmit,
  avatarURL,
  colorKey,
  className,
  disableScreenReader = false,
  ...props
}: CommentInputProps) => {
  const [value, setValue] = React.useState("");

  const COMMENT_MAX_LENGTH = 1000;
  const [commentInputId] = React.useState(uniqueId("commentInputId"));

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.keyCode !== 13) return;
    if (e.shiftKey) return;

    e.preventDefault();

    const comment = value.trim();
    if (comment === "") return;

    handleOnClickSubmit(e);
  };
  const handleOnClickSubmit = (e: React.UIEvent) => {
    e.preventDefault();
    onSubmit(value);
    if (!disableScreenReader) {
      announceToScreenReader(autoTranslate("Sent Comment Successfully"));
    }
    setValue("");
  };

  const isValueEmpty = !value.length;

  return (
    <form
      sx={{ display: "flex", width: "100%", gap: "s", alignItems: "center" }}
      data-name="submitCommentInputWrapper"
      className={className}
    >
      <ProfilePicture src={avatarURL} size="4.4rem" sx={{ flexShrink: 0 }} colorKey={colorKey} />
      <DDSTextarea
        label={autoTranslate("Write a comment")}
        maxLength={COMMENT_MAX_LENGTH}
        aria-label={autoTranslate("Write a comment")}
        value={value}
        id={commentInputId}
        onChange={(newValue: string) => setValue(newValue)}
        onKeyDown={handleKeyDown}
        data-name="submitCommentInput"
        rows={1}
        enableResize={false}
        {...props}
      >
        <UnstyledButton
          aria-label={autoTranslate("Send comment")}
          onClick={handleOnClickSubmit}
          sx={{
            position: "absolute",
            height: "100%",
            top: "0",
            right: "22px",
            display: "flex",
            alignItems: "center",
          }}
          data-name="submitCommentInputButton"
          disabled={value.trim() === ""}
          type="submit"
        >
          <SendIcon color={isValueEmpty ? "dt_content_disabled" : "dt_content_accent"} />
        </UnstyledButton>
      </DDSTextarea>
    </form>
  );
};

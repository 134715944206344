import { useState } from "react";
import * as React from "react";
import { useIntersectionObserverCallback } from "../hooks/useIntersectionObserver";
import useStatefulRef from "../hooks/useStatefulRef";

const INTERSECTION_RATIO = 0.5;

type VisibilitySensorProps = {
  onChange: (visible: boolean) => void;
  children: React.ReactNode;
};

const VisibilitySensor = ({ onChange, children }: VisibilitySensorProps): JSX.Element => {
  const wrapperRef = useStatefulRef<HTMLDivElement>(null);

  useIntersectionObserverCallback(wrapperRef, onChange, { threshold: INTERSECTION_RATIO });

  return <div ref={wrapperRef}>{children}</div>;
};

export const VisibilitySensorTestCase = (): JSX.Element => {
  const [visibilityState, setVisibilityState] = useState(false);
  const handleVisibilityChange = (value: boolean) => {
    setVisibilityState(value);
  };
  const perc = String(INTERSECTION_RATIO * 100);

  return (
    <div>
      <div sx={{ position: "fixed" }}>
        {visibilityState ? `Is more than ${perc}% visible` : `Is not visible or less than ${perc}% visible`}
      </div>
      <div sx={{ width: "200px", height: "1200px" }}></div>
      <VisibilitySensor onChange={handleVisibilityChange}>
        <div sx={{ width: "200px", height: "200px", backgroundColor: "dt_content_success" }}></div>
      </VisibilitySensor>
    </div>
  );
};

export default VisibilitySensor;

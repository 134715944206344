import { format, set } from "@web-monorepo/dates";
import { IconClock } from "@web-monorepo/dds-icons";
import { DDSSelect, DDSSelectContent, DDSSelectItem, DDSSelectTrigger, DDSSelectValue } from "../DDSSelect/DDSSelect";
import classes from "./DDSTimeSelect.module.css";

export interface DDSTimeSelectProps {
  /** The time value in 24-hour format (HH:MM) */
  value: string;
  /** Callback when the time changes */
  onValueChange: (value: string) => void;
  /** Optional label text */
  labelText?: string;
  /** The size of the select component */
  size?: "small" | "medium" | "large";
  /** When true, prevents the user from interacting with the select */
  disabled?: boolean;
  /** The side of the anchor where the content should be placed */
  side?: "top" | "bottom" | "left" | "right";
  /** Additional className to apply to the select */
  className?: string;
  /** Minimum time that can be selected (24-hour format HH:MM) */
  minTime?: string;
  /** Maximum time that can be selected (24-hour format HH:MM) */
  maxTime?: string;
  /** Interval in minutes between time options */
  interval?: 15 | 30 | 60;
}

/**
 * DDSTimeSelect - A time picker component using DDSSelect
 * Allows users to select a time in specified intervals using 24-hour format strings
 */
export const DDSTimeSelect = ({
  value,
  onValueChange,
  labelText = "Time",
  size = "medium",
  disabled = false,
  side = "bottom",
  className,
  minTime = "00:00",
  maxTime = "23:59",
  interval = 30,
}: DDSTimeSelectProps) => {
  // Generate time options in specified intervals
  const timeOptions = getTimeOptions(minTime, maxTime, interval);

  // Format the current time for display
  const formattedTime = formatTimeForDisplay(value);

  return (
    <DDSSelect size={size} value={value} onValueChange={onValueChange} disabled={disabled} className={className}>
      <DDSSelectTrigger aria-label={labelText}>
        <span className={classes.timeSelectWrapper}>
          <IconClock size="m" className={classes.timeIcon} />
          <DDSSelectValue>{formattedTime}</DDSSelectValue>
        </span>
      </DDSSelectTrigger>
      <DDSSelectContent side={side}>
        {timeOptions.map((timeOption) => (
          <DDSSelectItem key={timeOption} value={timeOption}>
            {formatTimeForDisplay(timeOption)}
          </DDSSelectItem>
        ))}
      </DDSSelectContent>
    </DDSSelect>
  );
};

/**
 * Format a 24-hour time string (HH:MM) to a localized display format
 * using the dates package
 */
function formatTimeForDisplay(timeString: string): string {
  // Create a temporary date with the time string
  const today = new Date();
  const dateWithTime = timeStringToDate(timeString, today);

  // Format using the dates package
  return format(dateWithTime, { timeStyle: "short" });
}

/**
 * Convert a time string (HH:MM) to a Date object
 */
function timeStringToDate(timeString: string, baseDate: Date = new Date()): Date {
  const [hours, minutes] = timeString.split(":").map(Number);

  return set(baseDate, {
    hours,
    minutes,
    seconds: 0,
    milliseconds: 0,
  });
}

/**
 * Generate an array of time strings in 24-hour format (HH:MM)
 * based on the specified min/max times and interval
 */
function getTimeOptions(minTime: string, maxTime: string, intervalMinutes: number): string[] {
  const options: string[] = [];

  // Convert min and max times to minutes since midnight for easier comparison
  const [maxHours, maxMinutes] = maxTime.split(":").map(Number);

  const maxMinutesSinceMidnight = maxHours * 60 + maxMinutes;

  // Generate time options at specified intervals
  for (let minutes = 0; minutes <= maxMinutesSinceMidnight; minutes += intervalMinutes) {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;

    // Format as HH:MM
    const timeString = `${hours.toString().padStart(2, "0")}:${mins.toString().padStart(2, "0")}`;

    if (timeString < minTime) continue;

    options.push(timeString);
  }

  return options;
}

import * as logClient from "@classdojo/log-client";
import createWaitableSaga from "@web-monorepo/infra/createWaitableSaga";
import i18n from "i18next";
import type { InitOptions } from "i18next";
import Backend from "i18next-http-backend";
import BackendAdapter from "i18next-multiload-backend-adapter";
import type { MultiloadBackendOptions } from "i18next-multiload-backend-adapter";
import set from "lodash/set";
import { initReactI18next } from "react-i18next";
import type { AnyAction, Middleware, Reducer } from "redux";
import type { Saga, SagaIterator } from "redux-saga";
import { call, put, select, take, takeEvery } from "redux-saga/effects";
import matchesAction from "../../utils/matchesAction";

type PodInstallFunction = {
  (
    installReducer: (stateKey: string, reducer: Reducer) => void,
    installSaga: (saga: Saga) => void,
    installPod: (pod: PodInstallFunction) => void,
    installMiddleware: (middleware: Middleware) => void,
  ): void;
};

type InitializeAction = {
  type: string;
  payload: {
    bundles: string[];
  };
};

type SetLocaleAction = {
  type: string;
  payload: {
    locale: string;
  };
};

const FALLBACKS: Record<string, string[]> = {
  "es-MX": ["es-ES", "en"], // Mexican Spanish
  "es-ES": ["es-MX", "en"], // Spanish Spanish
  "fr-CA": ["fr-FR", "en"], // Canadian French
  "fr-FR": ["fr-CA", "en"], // French French
  "zh-CN": ["zh-HK", "zh-TW", "en"],
  "zh-TW": ["zh-HK", "zh-CN", "en"],
  "zh-HK": ["zh-TW", "zh-CN", "en"],
  ca: ["es-ES", "es-MX", "en"], // Catalan
  en: ["en-US"],
  default: ["en"],
};

const build = Config.buildNumber || Date.now();
i18n
  .use(BackendAdapter)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next);

// for all options read: https://www.i18next.com/overview/configuration-options
const i18nOptions: InitOptions<MultiloadBackendOptions> = {
  lng: "en", // hard code until we have user languages
  defaultNS: "dojo.common",
  fallbackLng: FALLBACKS,

  // debug: true,

  backend: {
    backend: new Backend(),
    backendOption: {
      allowMultiLoading: true,
      crossDomain: true,
      loadPath: `/locales/resources.json?lng=__lng__&ns=__ns__&build=${build}`,
    },
  },

  interpolation: {
    prefix: "__",
    suffix: "__",
    escapeValue: false, // not needed for react as it escapes by default
  },

  react: {
    useSuspense: false,
  },
};

const STATE_KEY = "i18n";
const initialState: I18nState = {
  locale: "en", // default as en until session is loaded
  loadedLocale: null, // locale that has been loaded
  bundle: null, // bundle to fetch from the server
  bundles: [],
};

export type I18nState = {
  locale: string;
  loadedLocale: string | null;
  bundle: string | null;
  bundles: string[];
};

export type I18nStateSlice = {
  [STATE_KEY]: I18nState;
};

// ACTIONS
export const INITIALIZE = "frontend-infra/i18n/initialize";
const INITIALIZE_DONE = "frontend-infra/i18n/initialize_done";
const LOAD = "frontend-infra/i18n/load";
const LOAD_DONE = "frontend-infra/i18n/load_done";
const SET_LOCALE = "frontend-infra/i18n/set_locale";

// ACTION CREATORS
export function initialize(bundles: string[]) {
  return { type: INITIALIZE, payload: { bundles } };
}

export function setLocale(locale: string) {
  return {
    type: SET_LOCALE,
    payload: { locale },
  };
}

// SELECTORS
export const selectFinishedLoadingLocale = (state: I18nStateSlice) => !!state?.[STATE_KEY]?.loadedLocale;

export const selectLocale = (state: I18nStateSlice) => state?.[STATE_KEY]?.locale;

const selectBundles = (state: I18nStateSlice) => state?.[STATE_KEY]?.bundles;

// SAGAS
function loadNamespaces(bundles: string[]) {
  return i18n.loadNamespaces(bundles);
}

function loadLanguages(locale: string) {
  locale = mapLocaleToI18nLocale(locale);
  return i18n.changeLanguage(locale).catch((err: string[]) => {
    // err is an array of strings here. filter down to the locales we actually care about
    err = err.filter((errorStr: string) => errorStr.includes(locale));
    if (err.length) {
      throw new Error(`Failed to load language resources: ${err.join(", ")}`);
    }
  });
}

function* loadLocaleSaga(locale: string) {
  if (!locale) {
    locale = yield select(selectLocale);
  }
  const bundles: string[] = yield select(selectBundles);
  if (!bundles) {
    yield call(
      // eslint-disable-next-line no-console
      console.error,
      "ERROR: bundles is not set, translations not loaded. Remember to dispatch the i18n initialize action.",
    );
    return;
  }
  yield put({ type: LOAD });
  try {
    yield call(loadNamespaces, bundles);
    yield call(loadLanguages, locale);
    // eslint-disable-next-line no-catch-all/no-catch-all
  } catch (err) {
    // okay, we couldn't load the language for some reason. pretend it worked so that the frontend renders something.
    // BUT log to the backend so we can track it.
    logClient.logException(err instanceof Error ? err : new Error(JSON.stringify(err)), []);
  }
  yield put({ type: LOAD_DONE, payload: { locale } });
}

function* ensureLocaleSaga(): SagaIterator<void> {
  yield take(INITIALIZE_DONE);
  let currentLocale: string = yield select(selectLocale);

  yield takeEvery(
    SET_LOCALE,
    createWaitableSaga(function* (action: SetLocaleAction) {
      if (mapLocaleToI18nLocale(action.payload.locale) === mapLocaleToI18nLocale(currentLocale)) return;

      yield* loadLocaleSaga(action.payload.locale);
      currentLocale = yield select(selectLocale);
    } as (...args: unknown[]) => SagaIterator),
  );
}

function waitForInit(ns: string | string[], locale: string): Promise<InitOptions<MultiloadBackendOptions>> {
  return new Promise((resolve) => {
    i18n.on("initialized", (options: InitOptions<MultiloadBackendOptions>) => {
      resolve(options);
    });
    i18n.init<MultiloadBackendOptions>({
      ...i18nOptions,
      ns,
      lng: mapLocaleToI18nLocale(locale || i18nOptions.lng || ""),
    });
    // https://github.com/i18next/react-i18next/issues/925
    //This approach will not detect 'changes' reactively. However, because we manually trigger a 'reload' of the app
    //whenever the user changes the language
    i18n.on("languageChanged", (lng: string) => {
      document.documentElement.setAttribute("lang", lng);
    });
  });
}

const initializeSaga = createWaitableSaga(function* initializeSaga() {
  const action: InitializeAction = yield take(INITIALIZE);
  const locale: string = yield select(selectLocale);

  const i18nResult: InitOptions = yield call(waitForInit, action.payload.bundles, locale);
  yield put({ type: INITIALIZE_DONE, payload: { ...i18nResult, locale } });
});

// REDUCER
export function reducer(state = initialState, action: AnyAction): I18nState {
  if (matchesAction(action, INITIALIZE)) {
    return set(state, "bundles", action.payload.bundles);
  }

  if (matchesAction(action, INITIALIZE_DONE)) {
    return {
      ...state,
      locale: action.payload.locale,
      loadedLocale: action.payload.locale,
      bundles: action.payload.ns,
    };
  }

  if (matchesAction(action, LOAD)) {
    return set(state, "loadedLocale", null);
  }

  if (matchesAction(action, LOAD_DONE)) {
    return set(state, "loadedLocale", action.payload.locale);
  }

  if (matchesAction(action, SET_LOCALE)) {
    return set(state, "locale", action.payload.locale);
  }

  return state;
}

export { makeBackwardsCompatibleTranslate, makeTranslate } from "./translate";

// INSTALLER
const install: PodInstallFunction = (installReducer, installSaga) => {
  installReducer(STATE_KEY, reducer);
  installSaga(ensureLocaleSaga);
  installSaga(initializeSaga);
  installSaga(onLocaleChangeSaga);
};

export default install;

/**
 * This function covers edge cases regarding what locale actually gets fetched via i18next
 */
export function mapLocaleToI18nLocale(locale: string) {
  if (locale === "en-US") {
    // en-US and en are actually the same, because the source of the translations ("en")
    // is written by people in the US. Rewrite requests from "en-US" to "en" so we don't
    // fetch two bundles with the same data. Also because test environments only know how
    // to serve "en".
    return "en";
  }
  return locale;
}

type LocaleChangeHandler = (locale: string) => void;

const onLocaleChangeHandlers: LocaleChangeHandler[] = [];
export function onLocaleChange(handler: LocaleChangeHandler) {
  onLocaleChangeHandlers.push(handler);
}

function* onLocaleChangeSaga() {
  yield takeEvery(SET_LOCALE, function (action: SetLocaleAction) {
    const locale = action.payload.locale;
    onLocaleChangeHandlers.forEach((f) => f(locale));
  });
}

export const localeToString: Record<string, string> = {
  "en-US": "English (US)",
  "en-GB": "English (UK)",
  "id-ID": "Bahasa Indonesia",
  "ca-ES": "Català",
  "de-DE": "Deutsch",
  "es-MX": "Español",
  "es-ES": "Español (España)",
  "fr-CA": "Français (Canada)",
  "fr-FR": "Français (France)",
  "hr-HR": "Hrvatski",
  "it-IT": "Italiano",
  "lt-LT": "Lietuvių",
  "nl-NL": "Nederlands",
  "pl-PL": "Polski",
  "pt-BR": "Português (Brasil)",
  "pt-PT": "Português (Portugal)",
  "sk-SK": "Slovenčina",
  "sl-SI": "Slovenščina",
  "tr-TR": "Türkçe",
  "vi-VN": "Tiếng Việt",
  "be-BY": "Беларуская",
  "el-GR": "Ελληνικά",
  "bg-BG": "Български",
  "ru-RU": "Русский",
  "sr-RS": "Srpski",
  "uk-UA": "Українська",
  "he-IL": "עברית",
  "ar-AR": "العربية",
  "hi-IN": "हिन्दी",
  "bn-IN": "বাংলা",
  "pa-IN": "ਪੰਜਾਬੀ",
  "ko-KR": "한국어",
  "zh-TW": "繁體中文",
  "zh-CN": "简体中文",
  "ja-JP": "日本語",
  "th-TH": "ไทย",
  "fil-PH": "Pilipino",
  "ms-MY": "Bahasa Melayu",
  "ps-AC": "پښتو",
  "ps-BD": "پښتو",
  "ps-KY": "پښتو",
};

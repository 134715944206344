import * as React from "react";
import { ThemeUIStyleObject } from "../../nessie/stylingLib";

type ListProps = {
  children?: React.ReactNode;
  className?: string;
};

const List = ({ children, className, ...passedProps }: ListProps): JSX.Element => {
  const listStyle = { ...wrapperStyle };
  return (
    <ul {...passedProps} sx={listStyle} className={className}>
      {React.Children.map(children, (child) => {
        if (!child) {
          return null;
        }
        return <li sx={{ "&:first-of-type": { border: "none" }, borderTop: "dt_divider" }}>{child}</li>;
      })}
    </ul>
  );
};

export default List;

const wrapperStyle: ThemeUIStyleObject = {
  borderTop: "dt_divider",
  borderRadius: "dt_radius_s",
  listStyleType: "none",
  padding: 0,
  margin: 0,
};
